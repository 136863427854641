<template>
  <!--begin::Advance Table Widget 2-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start">
        <span class="card-label font-weight-bolder text-dark mt-3">
          Libreria Strategie
        </span>
        <div class="text-center">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on" small>
                Filtro Strategie {{ fActive == "" ? "" : "(" + fActive + ")" }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in items"
                :key="index"
                @click="getSubItems(item, $event)"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
              <v-divider class="mb-0"></v-divider>
              <v-list-item @click="getSubItems(item, $event)">
                <v-list-item-title class="font-weight-bolder font-size-sm"
                  >Vedi tutto</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </h3>
      <div class="card-toolbar">
        <ul class="nav nav-pills nav-pills-sm nav-dark-75">
          <li class="nav-item">
            <a
              class="nav-link py-2 px-4 btn font-weight-bolder font-size-sm"
              :class="{ active: this.show === 'Commodities' }"
              data-toggle="tab"
              @click="show = 'Commodities'"
            >
              Materie Prime
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link py-2 px-4 btn font-weight-bolder font-size-sm"
              data-toggle="tab"
              :class="{ active: this.show === 'Options' }"
              @click="show = 'Options'"
            >
              Opzioni
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link py-2 px-4 btn font-weight-bolder font-size-sm"
              data-toggle="tab"
              :class="{ active: this.show === 'Stocks' }"
              @click="show = 'Stocks'"
            >
              Azioni
            </a>
          </li>
        </ul>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-3 pb-0">
      <!--begin::Table for Commodities-->
      <div
        v-if="this.show === 'Commodities' && tc_commodities"
        class="table-responsive"
      >
        <table
          class="table table-borderless table-vertical-center table-head-bg"
        >
          <thead>
            <tr>
              <th style="min-width: 250px" class="pl-7 text-left">
                <span class="text-dark-75">NOME</span>
              </th>
              <th style="min-width: 120px; text-align: left">POSIZIONE</th>
              <th style="min-width: 120px; text-align: left">STRATEGIA</th>
              <th style="min-width: 120px; text-align: left">PERIODO</th>
              <th style="min-width: 120px; text-align: center">STATO</th>
              <th style="min-width: 100px; text-align: center">AVVIA</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in commodities">
              <tr v-bind:key="i">
                <td class="pl-0">
                  <a
                    class="
                      text-dark-75
                      font-weight-bolder
                      text-hover-primary
                      mb-1
                      font-size-lg
                    "
                  >
                    {{ item.generalForm.saveName }}
                  </a>
                  <div>
                    <a class="text-muted font-weight-bold text-hover-primary">
                      {{ item.generalForm.sector }}
                    </a>
                  </div>
                </td>
                <td>
                  <span
                    class="
                      text-dark-75
                      font-weight-bolder
                      text-hover-primary
                      mb-1
                      font-size-lg
                    "
                  >
                    {{ item.generalForm.buySell == "buy" ? "BUY" : "SELL" }}
                  </span>
                  <div>
                    <a class="text-muted font-weight-bold text-hover-primary">
                      {{ item.generalForm.seasonal }}
                    </a>
                  </div>
                </td>
                <td>
                  <span
                    class="
                      text-dark-75
                      font-weight-bolder
                      text-hover-primary
                      mb-1
                      font-size-lg
                    "
                  >
                    {{ item.generalForm.strategy }}
                  </span>
                  <div>
                    <a class="text-muted font-weight-bold text-hover-primary">
                      {{ item.generalForm.type }}
                    </a>
                  </div>
                </td>
                <td>
                  <span
                    class="
                      text-dark-75
                      font-weight-bolder
                      text-hover-primary
                      mb-1
                      font-size-lg
                    "
                  >
                    {{
                      createTradingPeriod(
                        item.generalForm.playback.start,
                        item.generalForm.playback.end
                      )
                    }}
                  </span>
                  <div class="d-none">
                    <a class="text-muted font-weight-bold text-hover-primary">
                      {{ item.generalForm.deadlines }}
                    </a>
                  </div>
                </td>
                <td>
                  <span
                    class="label label-lg label-inline"
                    style="width: 100px !important"
                    v-bind:class="
                      item.status == 'In progress'
                        ? 'label-warning'
                        : item.status == 'Skipped'
                        ? 'label-danger'
                        : item.status == 'Completed'
                        ? 'label-success'
                        : ''
                    "
                  >
                    {{
                      item.status == "In progress"
                        ? "In corso"
                        : item.status == "Skipped"
                        ? "Scartato"
                        : item.status == "Completed"
                        ? "Completato"
                        : "Non iniziato"
                    }}
                  </span>
                </td>
                <td class="pr-0 text-center">
                  <a
                    v-on:click="openEx(i, false)"
                    class="btn btn-icon btn btn-sm btn-outline-primary"
                    style="border: 2px solid; font-size: 8px"
                  >
                    <span class="svg-icon svg-icon-md">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        src="media/svg/icons/Media/Play.svg"
                        alt="Play"
                      ></inline-svg>
                      <!--end::Svg Icon-->
                    </span>
                  </a>
                  <a
                    v-if="role == 'admin' || role == 'superadmin'"
                    v-on:click="openEx(i, true)"
                    class="btn btn-icon btn btn-sm m-2"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-danger">
                      <inline-svg
                        style="width: 22px !important; height: 22px !important"
                        src="media/svg/icons/Media/Repeat.svg"
                      ></inline-svg>
                    </span>
                  </a>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <!--end::Table-->
      <div v-if="this.show === 'Commodities' && !tc_commodities">
        <div class="row">
          <div class="col-xl-12">
            <div class="text-center font-weight-normal font-size-lg p-7">
              <p class="text-center font-weight-normal font-size-lg p-7">
                Iscriviti al Trading Club in Commodity Spread per poter
                aggiungere strategie alla tua Lab Table in questa sezione!
              </p>
              <a
                target="_blank"
                href="https://academy.alpha4all.it/bundles/trading-club-pro-commodity-spread"
                class="
                  btn btn-success btn-shadow-hover
                  font-weight-bolder
                  w-100
                  py-3
                "
                style="width: 200px !important; text-align: center"
                >Vai al Trading Club</a
              >
            </div>
          </div>
        </div>
      </div>
      <!--begin::Table for Options-->
      <div
        v-if="this.show === 'Options' && tc_options"
        class="table-responsive"
      >
        <table
          class="table table-borderless table-vertical-center table-head-bg"
        >
          <thead>
            <tr>
              <th style="min-width: 250px" class="pl-7 text-left">
                <span class="text-dark-75">NOME</span>
              </th>
              <th style="min-width: 120px; text-align: left">POSIZIONE</th>
              <th style="min-width: 120px; text-align: left">STRATEGIA</th>
              <th style="min-width: 120px; text-align: left">PERIODO</th>
              <th style="min-width: 120px; text-align: center">STATO</th>
              <th style="min-width: 100px; text-align: center">AVVIA</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in options">
              <tr v-bind:key="i">
                <td class="pl-0">
                  <a
                    class="
                      text-dark-75
                      font-weight-bolder
                      text-hover-primary
                      mb-1
                      font-size-lg
                    "
                  >
                    {{ item.generalForm.saveName }}
                  </a>
                  <div>
                    <a class="text-muted font-weight-bold text-hover-primary">
                      {{ item.generalForm.sector }}
                    </a>
                  </div>
                </td>
                <td>
                  <span
                    class="
                      text-dark-75
                      font-weight-bolder
                      text-hover-primary
                      mb-1
                      font-size-lg
                    "
                  >
                    {{ item.generalForm.buySell == "buy" ? "BUY" : "SELL" }}
                  </span>
                </td>
                <td>
                  <span
                    class="
                      text-dark-75
                      font-weight-bolder
                      text-hover-primary
                      mb-1
                      font-size-lg
                    "
                  >
                    {{ item.generalForm.strategy }}
                  </span>
                  <div>
                    <a class="text-muted font-weight-bold text-hover-primary">
                      {{ item.generalForm.type }}
                    </a>
                  </div>
                </td>
                <td>
                  <span
                    class="
                      text-dark-75
                      font-weight-bolder
                      text-hover-primary
                      mb-1
                      font-size-lg
                    "
                  >
                    {{
                      createTradingPeriod(
                        item.generalForm.playback.start,
                        item.generalForm.playback.end
                      )
                    }}
                  </span>
                  <div>
                    <a class="text-muted font-weight-bold text-hover-primary">
                      {{ item.generalForm.deadlines }}
                    </a>
                  </div>
                </td>
                <td>
                  <span
                    class="label label-lg label-inline"
                    style="width: 100px !important"
                    v-bind:class="
                      item.status == 'In progress'
                        ? 'label-warning'
                        : item.status == 'Skipped'
                        ? 'label-danger'
                        : item.status == 'Completed'
                        ? 'label-success'
                        : ''
                    "
                  >
                    {{
                      item.status == "In progress"
                        ? "In corso"
                        : item.status == "Skipped"
                        ? "Scartato"
                        : item.status == "Completed"
                        ? "Completato"
                        : "Non iniziato"
                    }}
                  </span>
                </td>
                <td class="pr-0 text-center">
                  <a
                    v-on:click="openEx(i, false)"
                    class="btn btn-icon btn btn-sm btn-outline-primary"
                    style="border: 2px solid; font-size: 8px"
                  >
                    <span class="svg-icon svg-icon-md">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        src="media/svg/icons/Media/Play.svg"
                        alt="Play"
                      ></inline-svg>
                      <!--end::Svg Icon-->
                    </span>
                  </a>
                  <a
                    v-if="role == 'admin' || role == 'superadmin'"
                    v-on:click="openEx(i, true)"
                    class="btn btn-icon btn btn-sm m-2"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-danger">
                      <inline-svg
                        style="width: 22px !important; height: 22px !important"
                        src="media/svg/icons/Media/Repeat.svg"
                      ></inline-svg>
                    </span>
                  </a>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <!--end::Table-->
      <div v-if="this.show === 'Options' && !tc_options">
        <div class="row">
          <div class="col-xl-12">
            <div class="text-center font-weight-normal font-size-lg p-7">
              <p class="text-center font-weight-normal font-size-lg p-7">
                Iscriviti al Trading Club in Opzioni per poter aggiungere
                strategie alla tua Lab Table in questa sezione!
              </p>
              <a
                target="_blank"
                href="https://academy.alpha4all.it/bundles/trading-club-pro-opzioni"
                class="
                  btn btn-success btn-shadow-hover
                  font-weight-bolder
                  w-100
                  py-3
                "
                style="width: 200px !important; text-align: center"
                >Vai al Trading Club</a
              >
            </div>
          </div>
        </div>
      </div>
      <!--begin::Table for Stocks-->
      <div v-if="this.show === 'Stocks' && tc_stocks" class="table-responsive">
        <table
          class="table table-borderless table-vertical-center table-head-bg"
        >
          <thead>
            <tr>
              <th style="min-width: 250px" class="pl-7 text-left">
                <span class="text-dark-75">NOME</span>
              </th>
              <th style="min-width: 120px; text-align: left">POSIZIONE</th>
              <th style="min-width: 120px; text-align: left">STRATEGIA</th>
              <th style="min-width: 120px; text-align: left">PERIODO</th>
              <th style="min-width: 120px; text-align: center">STATO</th>
              <th style="min-width: 100px; text-align: center">AVVIA</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in stocks">
              <tr v-bind:key="i">
                <td class="pl-0">
                  <a
                    class="
                      text-dark-75
                      font-weight-bolder
                      text-hover-primary
                      mb-1
                      font-size-lg
                    "
                  >
                    {{ item.generalForm.saveName }}
                  </a>
                  <div>
                    <a class="text-muted font-weight-bold text-hover-primary">
                      {{ item.generalForm.sector }}
                    </a>
                  </div>
                </td>
                <td>
                  <span
                    class="
                      text-dark-75
                      font-weight-bolder
                      text-hover-primary
                      mb-1
                      font-size-lg
                    "
                  >
                    {{ item.generalForm.buySell == "buy" ? "BUY" : "SELL" }}
                  </span>
                </td>
                <td>
                  <span
                    class="
                      text-dark-75
                      font-weight-bolder
                      text-hover-primary
                      mb-1
                      font-size-lg
                    "
                  >
                    {{ item.generalForm.strategy }}
                  </span>
                  <div>
                    <a class="text-muted font-weight-bold text-hover-primary">
                      {{ item.generalForm.type }}
                    </a>
                  </div>
                </td>
                <td>
                  <span
                    class="
                      text-dark-75
                      font-weight-bolder
                      text-hover-primary
                      mb-1
                      font-size-lg
                    "
                  >
                    {{
                      createTradingPeriod(
                        item.generalForm.playback.start,
                        item.generalForm.playback.end
                      )
                    }}
                  </span>
                  <div>
                    <a class="text-muted font-weight-bold text-hover-primary">
                      {{ item.generalForm.deadlines }}
                    </a>
                  </div>
                </td>
                <td>
                  <span
                    class="label label-lg label-inline"
                    style="width: 100px !important"
                    v-bind:class="
                      item.status == 'In progress'
                        ? 'label-warning'
                        : item.status == 'Skipped'
                        ? 'label-danger'
                        : item.status == 'Completed'
                        ? 'label-success'
                        : ''
                    "
                  >
                    {{
                      item.status == "In progress"
                        ? "In corso"
                        : item.status == "Skipped"
                        ? "Scartato"
                        : item.status == "Completed"
                        ? "Completato"
                        : "Non iniziato"
                    }}
                  </span>
                </td>
                <td class="pr-0 text-center">
                  <a
                    v-on:click="openEx(i, false)"
                    class="btn btn-icon btn btn-sm btn-outline-primary"
                    style="border: 2px solid; font-size: 8px"
                  >
                    <span class="svg-icon svg-icon-md">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        src="media/svg/icons/Media/Play.svg"
                        alt="Play"
                      ></inline-svg>
                      <!--end::Svg Icon-->
                    </span>
                  </a>
                  <a
                    v-if="role == 'admin' || role == 'superadmin'"
                    v-on:click="openEx(i, true)"
                    class="btn btn-icon btn btn-sm m-2"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-danger">
                      <inline-svg
                        style="width: 22px !important; height: 22px !important"
                        src="media/svg/icons/Media/Repeat.svg"
                      ></inline-svg>
                    </span>
                  </a>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <!--end::Table-->
      <div v-if="this.show === 'Stocks' && !tc_stocks">
        <div class="row">
          <div class="col-xl-12">
            <div class="text-center font-weight-normal font-size-lg p-7">
              <p class="text-center font-weight-normal font-size-lg p-7">
                Iscriviti al Trading Club in Azioni per poter aggiungere
                strategie alla tua Lab Table in questa sezione!
              </p>
              <a
                target="_blank"
                href="https://academy.alpha4all.it/bundles/trading-club-pro-azioni"
                class="
                  btn btn-success btn-shadow-hover
                  font-weight-bolder
                  w-100
                  py-3
                "
                style="width: 200px !important; text-align: center"
                >Vai al Trading Club</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end::Body-->
    <!--end::Advance Table Widget 2-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as fb from "../../../../firebase";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { monthNames } from "../../../../js/chartslab";

export default {
  name: "widget-2",
  data() {
    return {
      show: "",
      commodities: [],
      options: [],
      stocks: [],
      all: {
        commodities: [],
        options: [],
        stocks: [],
      },
      status: {
        commodities: "green",
        options: "green",
        stocks: "green",
      },
      tc_commodities: [],
      tc_stocks: [],
      tc_options: [],
      tc_interval_commodities: "month",
      tc_interval_stocks: "month",
      tc_interval_options: "month",
      filters: {
        commodities: [
          { title: "Argomento", subItems: [] },
          { title: "Settore", subItems: [] },
          {
            title: "Posizione",
            subItems: [{ title: "BUY" }, { title: "SELL" }],
          },
          {
            title: "Stagionalita'",
            subItems: [{ title: "Rialzista" }, { title: "Ribassista" }],
          },
          {
            title: "Strategia",
            subItems: [
              { title: "Future" },
              { title: "Calendar" },
              { title: "Butterfly" },
              { title: "Condor" },
            ],
          },
          {
            title: "Tipo",
            subItems: [{ title: "Intramarket" }, { title: "Intermarket" }],
          },
          {
            title: "Stato",
            subItems: [
              { title: "Non iniziato" },
              { title: "In corso" },
              { title: "Completato" },
              { title: "Scartato" },
            ],
          },
        ],
        options: [
          { title: "Settore", subItems: [] },
          {
            title: "Posizione",
            subItems: [{ title: "BUY" }, { title: "SELL" }],
          },
          {
            title: "Strategia",
            subItems: [
              { title: "Future" },
              { title: "Vertical" },
              { title: "Butterfly" },
              { title: "Condor" },
            ],
          },
          {
            title: "Stato",
            subItems: [
              { title: "Non iniziato" },
              { title: "In corso" },
              { title: "Completato" },
              { title: "Scartato" },
            ],
          },
        ],
        stocks: [
          { title: "Argomento", subItems: [] },
          { title: "Settore", subItems: [] },
          {
            title: "Posizione",
            subItems: [{ title: "BUY" }, { title: "SELL" }],
          },
          {
            title: "Strategia",
            subItems: [{ title: "Stock" }, { title: "Pair Trading" }],
          },
          {
            title: "Stato",
            subItems: [
              { title: "Non iniziato" },
              { title: "In corso" },
              { title: "Completato" },
              { title: "Scartato" },
            ],
          },
        ],
      },
      items: [],
      showSubItems: false,
      showItems: true,
      fActive: "",
    };
  },
  components: {},
  methods: {
    getSubItems(val, event) {
      if (this.show == "Options") {
        this.items = this.filters.options;
      } else if (this.show == "Stocks") {
        this.items = this.filters.stocks;
      } else {
        this.items = this.filters.commodities;
      }
      if (val != undefined && val.subItems != undefined) {
        event.stopPropagation();
        this.items = val.subItems;
        this.applyFilter("");
        this.fActive = val.title;
      } else {
        if (val == undefined) {
          this.applyFilter("", "");
          this.fActive = "";
        } else {
          this.applyFilter(this.fActive, val.title);
          this.fActive = val.title;
        }
      }
    },
    applyFilter(category, value) {
      // Status management
      if (category == "Stato") {
        if (value == "In corso") value = "In progress";
        if (value == "Non iniziato") value = "Not started";
        if (value == "Scartato") value = "Skipped";
        if (value == "Completato") value = "Completed";
      }
      if (this.show == "Commodities") {
        if (category == "Argomento")
          this.commodities = this.commodities.filter(
            (x) => x.generalForm.saveName.indexOf(value) >= 0
          );
        if (category == "Settore")
          this.commodities = this.commodities.filter(
            (x) => x.generalForm.sector.indexOf(value) >= 0
          );
        if (category == "Stato")
          this.commodities = this.commodities.filter((x) => x.status == value);
        if (category == "Stagionalita'")
          this.commodities = this.commodities.filter(
            (x) => x.generalForm.seasonal.indexOf(value) >= 0
          );
        if (category == "Tipo")
          this.commodities = this.commodities.filter(
            (x) => x.generalForm.type.indexOf(value) >= 0
          );
        if (category == "Posizione") {
          if (value == "BUY")
            this.commodities = this.commodities.filter(
              (x) => x.generalForm.buySell.indexOf(value.toLowerCase()) >= 0
            );
          if (value == "SELL")
            this.commodities = this.commodities.filter(
              (x) => x.generalForm.buySell.indexOf(value.toLowerCase()) >= 0
            );
        }
        if (category == "Strategia")
          this.commodities = this.commodities.filter(
            (x) => x.generalForm.strategy.indexOf(value) >= 0
          );
        if (category == "") this.commodities = this.all.commodities;
      }
      if (this.show == "Options") {
        this.items = this.items.filter(function (e) {
          return (
            e.title !== "Argomento" &&
            e.title !== "Tipo" &&
            e.title !== "Stagionalita'"
          );
        });
        if (category == "Settore")
          this.options = this.options.filter(
            (x) => x.generalForm.sector.indexOf(value) >= 0
          );
        if (category == "Stato")
          this.options = this.options.filter((x) => x.status == value);
        if (category == "Posizione") {
          if (value == "BUY")
            this.options = this.options.filter(
              (x) => x.generalForm.buySell.indexOf(value.toLowerCase()) >= 0
            );
          if (value == "SELL")
            this.options = this.options.filter(
              (x) => x.generalForm.buySell.indexOf(value.toLowerCase()) >= 0
            );
        }
        if (category == "Strategia")
          this.options = this.options.filter(
            (x) => x.generalForm.strategy.indexOf(value) >= 0
          );
        if (category == "") this.options = this.all.options;
      }
      if (this.show == "Stocks") {
        if (category == "Argomento")
          this.stocks = this.stocks.filter(
            (x) => x.generalForm.saveName.indexOf(value) >= 0
          );
        if (category == "Settore")
          this.stocks = this.stocks.filter(
            (x) => x.generalForm.sector.indexOf(value) >= 0
          );
        if (category == "Stato")
          this.stocks = this.stocks.filter((x) => x.status == value);
        if (category == "Posizione") {
          if (value == "BUY")
            this.stocks = this.stocks.filter(
              (x) => x.generalForm.buySell.indexOf(value.toLowerCase()) >= 0
            );
          if (value == "SELL")
            this.stocks = this.stocks.filter(
              (x) => x.generalForm.buySell.indexOf(value.toLowerCase()) >= 0
            );
        }
        if (category == "Strategia")
          this.stocks = this.stocks.filter(
            (x) => x.generalForm.strategy.indexOf(value) >= 0
          );
        if (category == "") this.stocks = this.all.stocks;
      }
    },
    updateLibrary(email) {
      var that = this;
      var mainQuery = fb.db
        .collection("saved-charts")
        .doc("chartslab@system.com")
        .collection("charts")
        .orderBy("generalForm.created", "desc");
      mainQuery.onSnapshot((querySnapshot) => {
        var library = [];
        this.commodities = [];
        this.options = [];
        this.stocks = [];
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          library.push(doc);
          console.log(doc.id, " => ", doc.data());
          var isSuperUser =
            this.$store.state.user.role.indexOf("admin") >= 0 ||
            this.$store.state.user.role == "operator";
          var docRef = fb.db
            .collection("portfolio")
            .doc(email)
            .collection("charts")
            .doc(doc.id);
          var data = doc.data();
          data.id = doc.id;
          docRef
            .get()
            .then((doc2) => {
              if (doc2.exists) {
                var status = doc2.data().status;
                switch (status) {
                  case "break":
                  case "ready_to_go":
                    data.status = "In progress";
                    break;
                  case "completed":
                    data.status = "Completed";
                    break;
                  case "skipped":
                    data.status = "Skipped";
                    break;
                  default:
                    // code block
                    data.status = "Not started";
                }
                console.log("Document data:", doc.data());
              } else {
                data.status = "Not started";
                // doc.data() will be undefined in this case
                console.log("No such document!");
              }
              if (
                data.generalForm.asset == "Commodities" &&
                (doc.data().generalForm.status == "public" || isSuperUser)
              ) {
                this.commodities.push(data);
                this.all.commodities.push(data);
                var titleArg = data.generalForm.saveName
                  .replace(/[0-9]/g, "")
                  .trim();
                if (
                  that.filters.commodities
                    .find((x) => x.title == "Argomento")
                    .subItems.find((x) => x.title == titleArg) == undefined
                ) {
                  var subItemArg = { title: titleArg };
                  that.filters.commodities
                    .find((x) => x.title == "Argomento")
                    .subItems.push(subItemArg);
                }
                var titleSec = data.generalForm.sector.trim();
                if (
                  that.filters.commodities
                    .find((x) => x.title == "Settore")
                    .subItems.find((x) => x.title == titleSec) == undefined
                ) {
                  var subItemSec = { title: titleSec };
                  that.filters.commodities
                    .find((x) => x.title == "Settore")
                    .subItems.push(subItemSec);
                }
              }
              if (
                data.generalForm.asset == "Options" &&
                (doc.data().generalForm.status == "public" || isSuperUser)
              ) {
                this.options.push(data);
                this.all.options.push(data);
                titleSec = data.generalForm.sector.trim();
                if (
                  that.filters.options
                    .find((x) => x.title == "Settore")
                    .subItems.find((x) => x.title == titleSec) == undefined
                ) {
                  subItemSec = { title: titleSec };
                  that.filters.options
                    .find((x) => x.title == "Settore")
                    .subItems.push(subItemSec);
                }
              }
              if (
                data.generalForm.asset == "Stocks" &&
                (doc.data().generalForm.status == "public" || isSuperUser)
              ) {
                this.stocks.push(data);
                this.all.stocks.push(data);
                titleArg = data.generalForm.saveName
                  .replace(/[0-9]/g, "")
                  .trim();
                if (
                  that.filters.stocks
                    .find((x) => x.title == "Argomento")
                    .subItems.find((x) => x.title == titleArg) == undefined
                ) {
                  subItemArg = { title: titleArg };
                  that.filters.stocks
                    .find((x) => x.title == "Argomento")
                    .subItems.push(subItemArg);
                }
                titleSec = data.generalForm.sector.trim();
                if (
                  that.filters.stocks
                    .find((x) => x.title == "Settore")
                    .subItems.find((x) => x.title == titleSec) == undefined
                ) {
                  subItemSec = { title: titleSec };
                  that.filters.stocks
                    .find((x) => x.title == "Settore")
                    .subItems.push(subItemSec);
                }
              }
            })
            .catch((error) => {
              console.log("Error getting document:", error);
            });
        });
      });
    },
    createTradingPeriod(start, end) {
      var monthStart = monthNames[new Date(start).getMonth()];
      var monthEnd = monthNames[new Date(end).getMonth()];
      return monthStart + " - " + monthEnd;
    },
    openExById(i, repeat) {
      var library = [];
      if (this.show === "Commodities") library = this.commodities;
      if (this.show === "Options") library = this.options;
      if (this.show === "Stocks") library = this.stocks;
      var position = library.indexOf(library.find((x) => x.id == i));
      this.openEx(position, repeat);
    },
    openEx(i, repeat) {
      var that = this;
      var library = [];
      if (this.show === "Commodities") library = this.commodities;
      if (this.show === "Options") library = this.options;
      if (this.show === "Stocks") library = this.stocks;
      var docId = library[i].id;
      var docRef = fb.db
        .collection("portfolio")
        .doc(this.$store.state.user.email)
        .collection("charts")
        .doc(docId);
      docRef
        .get()
        .then((doc2) => {
          if (doc2.exists) {
            library[i].level = doc2.data().level;
            library[i].pl = doc2.data().pl;
            library[i].break = doc2.data().status == "break";
            library[i].repeat = repeat;
            if (repeat) {
              this.$parent.showOnlyWizard(library[i]);
            } else if (
              doc2.data().status == "ready_to_go" ||
              doc2.data().status == "break"
            ) {
              this.$parent.showSimulator(library[i]);
            } else {
              this.$parent.showResult(library[i]);
            }
          } else {
            var status;
            var interval;
            if (that.show === "Commodities") {
              status = that.status.commodities;
              interval = that.tc_interval_commodities;
            }
            if (that.show === "Options") {
              status = that.status.options;
              interval = that.tc_interval_options;
            }
            if (that.show === "Stocks") {
              status = that.status.stocks;
              interval = that.tc_interval_stocks;
            }
            if (status == "green" || repeat || interval == "year") {
              library[i].repeat = repeat;
              this.$parent.showOnlyWizard(library[i]);
            } else {
              Swal.fire({
                title: "Raggiunto numero di esercitazioni massime",
                text: "Attendi la prossima settimana per accedere alle nuove!",
                icon: "alert",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Chiudi",
              });
            }
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    },
  },
  watch: {
    show() {
      this.$root.$emit("update_view", this.show); //like this
      this.$root.$emit("update_equity", this.show); //like this
      this.getSubItems();
    },
    email: {
      immediate: true,
      handler(email) {
        //var email = this.$store.state.user.email;
        if (email !== "") {
          if (this.$store.state.user.subscriptions != undefined) {
            var sub = isActive(
              this.$store.state.user.subscriptions,
              "commodities"
            );
            this.tc_commodities = sub.length != 0 ? true : false;
            if (this.tc_commodities) {
              this.show = "Commodities";
              this.tc_interval_commodities = sub[0].data[0].interval;
            }
            sub = isActive(this.$store.state.user.subscriptions, "options");
            this.tc_options = sub.length != 0 ? true : false;
            if (this.tc_options)
              this.tc_interval_options = sub[0].data[0].interval;
            if (!this.tc_commodities && this.tc_options) this.show = "Options";
            sub = isActive(this.$store.state.user.subscriptions, "stocks");
            this.tc_stocks = sub.length != 0 ? true : false;
            if (this.tc_stocks)
              this.tc_interval_stocks = sub[0].data[0].interval;
            //this.$root.$emit('update_view', this.show); //like this
            if (!this.tc_commodities && !this.tc_options && this.tc_stocks)
              this.show = "Stocks";
          } else {
            if (
              this.$store.state.user.role.indexOf("admin") >= 0 ||
              this.$store.state.user.role == "operator"
            ) {
              this.tc_commodities = true;
              this.tc_options = true;
              this.tc_stocks = true;
            } else {
              this.tc_commodities = false;
              this.tc_options = false;
              this.tc_stocks = false;
            }
            this.show = "Commodities";
          }
          this.updateLibrary(email);
        }
      },
    },
  },
  mounted() {
    var that = this;
    this.$root.$on("open_ex", (value, repeat) => {
      // your code goes here
      that.openExById(value, repeat);
    });
    this.$root.$on("update_status", (value) => {
      // your code goes here
      that.status = value;
    });
    this.$root.$on("update_assets", (value) => {
      // your code goes here
      that.updateLibrary(value);
    });
    this.$root.$on("update_personal_library", (value, pl, date) => {
      var docRef = fb.db
        .collection("portfolio")
        .doc(this.$store.state.user.email)
        .collection("charts")
        .doc(value);
      docRef
        .get()
        .then(function (thisDoc) {
          if (thisDoc.exists) {
            var obj = thisDoc.data();
            if (date == undefined) {
              obj.status = "completed";
            } else {
              obj.status = "break";
            }
            obj.pl = pl;
            obj.upins_date = new Date();
            if (date != undefined) obj.breakpoint_date = new Date(date);
            docRef.update(obj);
            if (thisDoc.asset == "Commodities")
              that.commodities.find((x) => x.id == thisDoc.id).status =
                "Completed";
            if (thisDoc.asset == "Options")
              that.options.find((x) => x.id == thisDoc.id).status = "Completed";
            if (thisDoc.asset == "Stocks")
              that.stocks.find((x) => x.id == thisDoc.id).status = "Completed";
            //that.$root.$emit('refresh_page'); //like this
          }
        })
        .catch(function (error) {
          console.log(error.message);
        });
    });
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    email() {
      return this.$store.state.user.email;
    },
    role() {
      return this.$store.state.user.role;
    },
  },
};

function isActive(subs, asset) {
  var yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  if (
    subs
      .filter((x) => x.name == "chartslab")
      .filter((x) => x.data[0].type == asset)
  ) {
    return subs
      .filter((x) => x.name == "chartslab")
      .filter((x) => x.data[0].type == asset)
      .filter((x) => {
        return new Date(x.data[0].expDate.seconds * 1000) >= yesterday;
      });
  }
}
</script>
<style>
.v-btn:not(.v-btn--round).v-size--small {
  height: 33px !important;
  font-size: 10px !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.v-list-item--link {
  text-align: center;
}
#intro img {
  width: 100%;
}
</style>
