<template>
  <!--begin::Mixed Widget 14-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 pl-5 pt-5">
      <h3 class="card-title font-weight-bolder">
        {{
          asset == "Commodities"
            ? "Materie Prime"
            : asset == "Stocks"
            ? "Azioni"
            : "Opzioni"
        }}
      </h3>
    </div>
    <span class="text-dark-75 font-weight-nirmal font-size-lg m-0 pl-5"
      >L'andamento per settimana.</span
    >
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body d-flex flex-column">
      <div>
        <apexchart
          ref="realtimeChart"
          :options="chartOptions"
          :series="series"
          type="area"
          height="200"
        ></apexchart>
      </div>
      <div class="pt-4" v-if="asset == 'Commodities'">
        <!--begin::Text-->
        <h3 class="card-title font-weight-bolder font-size-lg mt-3">
          Le esercitazioni della settimana
        </h3>
        <p
          v-if="
            subscriptions.find(
              (x) =>
                x.data[0].type == 'commodities' && x.data[0].interval != 'year'
            )
          "
          class="text-dark-75 font-weight-nirmal font-size-lg m-0 pb-7"
        >
          Ogni settimana sarà possibile eseguire 3 esercitazioni.
        </p>
        <p
          v-if="
            subscriptions.find(
              (x) =>
                x.data[0].type == 'commodities' && x.data[0].interval == 'year'
            )
          "
          class="text-dark-75 font-weight-nirmal font-size-lg m-0 pb-7"
        >
          In basso trovi le tue ultime 3 esercitazioni
        </p>
        <!--end::Text-->
        <div
          v-for="(record, i) in portfolioCommodities.slice(
            portfolioCommodities.length - 3 > 0
              ? portfolioCommodities.length - 3
              : 0,
            portfolioCommodities.length
          )"
          v-bind:key="i"
          style="font-size: 13px; text-align: left"
        >
          <!--begin::Item-->
          <div class="d-flex align-items-center pb-4">
            <!--begin::Text-->
            <div class="d-flex flex-column flex-grow-1">
              <div class="row">
                <div class="col-6">
                  <a
                    class="
                      text-dark-75 text-hover-primary
                      font-size-lg font-weight-bolder
                    "
                    >{{ record.extra.generalForm.saveName }}</a
                  >
                  <p class="text-muted font-weight-bold">
                    {{ record.extra.generalForm.sector }}
                  </p>
                  <!--span class="font-weight-bold">Data ultimo aggiornamento:</span-->
                </div>
                <div class="col-3 mt-2 p-0">
                  <span
                    v-if="
                      record.data.pl != undefined &&
                      record.data.status == 'Completed'
                    "
                    class="font-weight-bolder label label-lg label-inline mt-1"
                    v-bind:class="
                      record.data.pl > 0
                        ? 'label-success p-1 m-0'
                        : record.data.pl == 0
                        ? 'label-gray p-1 m-0'
                        : record.data.pl == undefined
                        ? ''
                        : 'label-danger'
                    "
                    style="padding: 12px 10px !important"
                    >{{
                      record.data.status == "Completed"
                        ? "$" + record.data.pl
                        : ""
                    }}</span
                  >
                </div>
                <div class="col-1 mt-2 p-0">
                  <a
                    title="Completato"
                    v-b-tooltip.hover
                    v-if="record.data.status == 'Completed'"
                    class="la la-check-circle text-success"
                    style="
                      font-size: 31px;
                      font-weight: 100;
                      cursor: default;
                      margin-left: -10px;
                    "
                  ></a>
                  <a
                    title="In corso"
                    v-b-tooltip.hover
                    v-if="record.data.status == 'In progress'"
                    class="la la-pause-circle-o text-warning"
                    style="
                      font-size: 35px;
                      font-weight: 100;
                      cursor: default;
                      margin-left: -12px;
                      margin-top: -8px;
                    "
                  ></a>
                  <a
                    title="Scartato"
                    v-b-tooltip.hover
                    v-if="record.data.status == 'Skipped'"
                    class="la la-times-circle text-danger"
                    style="
                      font-size: 30px;
                      font-weight: 100;
                      cursor: default;
                      margin-left: -10px;
                    "
                  ></a>
                </div>
                <div class="col-1 mt-1 p-0" style="margin-left: -10px">
                  <a
                    v-on:click="openExercise(record.data.id)"
                    title="Play"
                    v-if="record.data.status == 'In progress'"
                    class="btn btn-icon btn btn-sm btn-outline-primary"
                    style="
                      margin-top: 0px;
                      border: solid 2px;
                      font-size: 7px;
                      cursor: pointer;
                      margin-left: 5px;
                    "
                  >
                    <span class="svg-icon svg-icon-md">
                      <svg
                        version="1.1"
                        viewBox="0 0 24 24"
                        height="24px"
                        width="24px"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xmlns="http://www.w3.org/2000/svg"
                        alt="Play"
                        fill="#3F4254"
                      >
                        <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
                        <title xmlns="http://www.w3.org/2000/svg">Play</title>
                        <g
                          xmlns="http://www.w3.org/2000/svg"
                          id="Stockholm-icons-/-Media-/-Play"
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <rect
                            id="bound"
                            x="0"
                            y="0"
                            width="24"
                            height="24"
                          ></rect>
                          <path
                            d="M9.82866499,18.2771971 L16.5693679,12.3976203 C16.7774696,12.2161036 16.7990211,11.9002555 16.6175044,11.6921539 C16.6029128,11.6754252 16.5872233,11.6596867 16.5705402,11.6450431 L9.82983723,5.72838979 C9.62230202,5.54622572 9.30638833,5.56679309 9.12422426,5.7743283 C9.04415337,5.86555116 9,5.98278612 9,6.10416552 L9,17.9003957 C9,18.1765381 9.22385763,18.4003957 9.5,18.4003957 C9.62084305,18.4003957 9.73759731,18.3566309 9.82866499,18.2771971 Z"
                            id="Path-10"
                            fill="#3F4254"
                          ></path>
                        </g>
                      </svg>
                    </span>
                  </a>
                  <a
                    title="Dettaglio"
                    v-b-tooltip.hover
                    v-on:click="openExercise(record.data.id)"
                    v-if="
                      record.data.status == 'Completed' ||
                      record.data.status == 'Skipped'
                    "
                    class="la la-comment text-primary-75"
                    style="font-size: 35px; font-weight: 100; cursor: pointer"
                  >
                  </a>
                </div>
                <div class="col-1 mt-1 p-0">
                  <a
                    title="Ripeti"
                    v-b-tooltip.hover
                    v-if="record.data.status == 'Completed'"
                    v-on:click="openExercise(record.data.id, true)"
                    class="btn btn-icon btn btn-sm"
                    style="margin-top: 2px"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-danger">
                      <inline-svg
                        style="width: 33px !important; height: 33px !important"
                        src="media/svg/icons/Media/Repeat.svg"
                      ></inline-svg>
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <!--end::Text-->
          </div>
          <!--end::Item-->
        </div>
        <!--begin::Item-->
        <div
          v-if="portfolioCommodities.length <= 2"
          class="d-flex align-items-center pb-4"
        >
          <!--begin::Text-->
          <div class="d-flex flex-column flex-grow-1">
            <a
              href="#"
              class="
                text-dark-75 text-hover-primary
                mb-1
                font-size-lg font-weight-bolder
              "
              >Slot libero</a
            >
            <span class="text-muted font-weight-bold"
              >Selezionare un esercizio dalla libreria generale</span
            >
          </div>
          <!--end::Text-->
        </div>
        <!--end::Item-->
        <!--begin::Item-->
        <div
          v-if="portfolioCommodities.length <= 1"
          class="d-flex align-items-center pb-4"
        >
          <!--begin::Text-->
          <div class="d-flex flex-column flex-grow-1">
            <a
              href="#"
              class="
                text-dark-75 text-hover-primary
                mb-1
                font-size-lg font-weight-bolder
              "
              >Slot libero</a
            >
            <span class="text-muted font-weight-bold"
              >Selezionare un esercizio dalla libreria generale</span
            >
          </div>
          <!--end::Text-->
        </div>
        <!--end::Item-->
        <!--begin::Item-->
        <div
          v-if="portfolioCommodities.length == 0"
          class="d-flex align-items-center pb-4"
        >
          <!--begin::Text-->
          <div class="d-flex flex-column flex-grow-1">
            <a
              href="#"
              class="
                text-dark-75 text-hover-primary
                mb-1
                font-size-lg font-weight-bolder
              "
              >Slot libero</a
            >
            <span class="text-muted font-weight-bold"
              >Selezionare un esercizio dalla libreria generale</span
            >
          </div>
          <!--end::Text-->
        </div>
        <!--end::Item-->
        <div
          class="d-flex"
          style="align-items: center; height: calc(20vh - 100px)"
        >
          <a
            v-on:click="openPortfolio()"
            class="btn btn-primary font-weight-bolder font-size-sm py-2 px-4"
            style="
              margin-left: auto;
              margin-right: auto;
              display: block;
              width: 150px;
            "
            >Vai al portfolio</a
          >
        </div>
      </div>
      <div class="pt-1" v-if="asset == 'Options'">
        <!--begin::Text-->
        <h3 class="card-title font-weight-bolder font-size-lg mt-3">
          Le esercitazioni della settimana
        </h3>
        <p
          v-if="
            subscriptions.find(
              (x) => x.data[0].type == 'options' && x.data[0].interval != 'year'
            )
          "
          class="text-dark-75 font-weight-nirmal font-size-lg m-0 pb-7"
        >
          Ogni settimana sarà possibile eseguire 3 esercitazioni.
        </p>
        <p
          v-if="
            subscriptions.find(
              (x) => x.data[0].type == 'options' && x.data[0].interval == 'year'
            )
          "
          class="text-dark-75 font-weight-nirmal font-size-lg m-0 pb-7"
        >
          In basso trovi le tue ultime 3 esercitazioni
        </p>
        <!--end::Text-->
        <div
          v-for="(record, i) in portfolioOptions.slice(
            portfolioOptions.length - 3 > 0 ? portfolioOptions.length - 3 : 0,
            portfolioOptions.length
          )"
          v-bind:key="i"
          style="font-size: 13px; text-align: left"
        >
          <!--begin::Item-->
          <div class="d-flex align-items-center pb-4">
            <!--begin::Text-->
            <div class="d-flex flex-column flex-grow-1">
              <div class="row">
                <div class="col-6">
                  <a
                    class="
                      text-dark-75 text-hover-primary
                      font-size-lg font-weight-bolder
                    "
                    >{{ record.extra.generalForm.saveName }}</a
                  >
                  <p class="text-muted font-weight-bold">
                    {{ record.extra.generalForm.sector }}
                  </p>
                  <!--span class="font-weight-bold">Data ultimo aggiornamento:</span-->
                </div>
                <div class="col-3 mt-2 p-0">
                  <span
                    v-if="
                      record.data.pl != undefined &&
                      record.data.status == 'Completed'
                    "
                    class="font-weight-bolder label label-lg label-inline mt-1"
                    v-bind:class="
                      record.data.pl > 0
                        ? 'label-success p-1 m-0'
                        : record.data.pl == 0
                        ? 'label-gray p-1 m-0'
                        : record.data.pl == undefined
                        ? ''
                        : 'label-danger'
                    "
                    style="padding: 12px 10px !important"
                    >{{
                      record.data.status == "Completed"
                        ? "$" + record.data.pl
                        : ""
                    }}</span
                  >
                </div>
                <div class="col-1 mt-2 p-0">
                  <a
                    title="Completato"
                    v-b-tooltip.hover
                    v-if="record.data.status == 'Completed'"
                    class="la la-check-circle text-success"
                    style="
                      font-size: 31px;
                      font-weight: 100;
                      cursor: default;
                      margin-left: -10px;
                    "
                  ></a>
                  <a
                    title="In corso"
                    v-b-tooltip.hover
                    v-if="record.data.status == 'In progress'"
                    class="la la-pause-circle-o text-warning"
                    style="
                      font-size: 35px;
                      font-weight: 100;
                      cursor: default;
                      margin-left: -12px;
                      margin-top: -8px;
                    "
                  ></a>
                  <a
                    title="Scartato"
                    v-b-tooltip.hover
                    v-if="record.data.status == 'Skipped'"
                    class="la la-times-circle text-danger"
                    style="
                      font-size: 30px;
                      font-weight: 100;
                      cursor: default;
                      margin-left: -10px;
                    "
                  ></a>
                </div>
                <div class="col-1 mt-1 p-0" style="margin-left: -10px">
                  <a
                    v-on:click="openExercise(record.data.id)"
                    title="Play"
                    v-if="record.data.status == 'In progress'"
                    class="btn btn-icon btn btn-sm btn-outline-primary"
                    style="
                      margin-top: 0px;
                      border: solid 2px;
                      font-size: 7px;
                      cursor: pointer;
                      margin-left: 5px;
                    "
                  >
                    <span class="svg-icon svg-icon-md">
                      <svg
                        version="1.1"
                        viewBox="0 0 24 24"
                        height="24px"
                        width="24px"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xmlns="http://www.w3.org/2000/svg"
                        alt="Play"
                        fill="#3F4254"
                      >
                        <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
                        <title xmlns="http://www.w3.org/2000/svg">Play</title>
                        <g
                          xmlns="http://www.w3.org/2000/svg"
                          id="Stockholm-icons-/-Media-/-Play"
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <rect
                            id="bound"
                            x="0"
                            y="0"
                            width="24"
                            height="24"
                          ></rect>
                          <path
                            d="M9.82866499,18.2771971 L16.5693679,12.3976203 C16.7774696,12.2161036 16.7990211,11.9002555 16.6175044,11.6921539 C16.6029128,11.6754252 16.5872233,11.6596867 16.5705402,11.6450431 L9.82983723,5.72838979 C9.62230202,5.54622572 9.30638833,5.56679309 9.12422426,5.7743283 C9.04415337,5.86555116 9,5.98278612 9,6.10416552 L9,17.9003957 C9,18.1765381 9.22385763,18.4003957 9.5,18.4003957 C9.62084305,18.4003957 9.73759731,18.3566309 9.82866499,18.2771971 Z"
                            id="Path-10"
                            fill="#3F4254"
                          ></path>
                        </g>
                      </svg>
                    </span>
                  </a>
                  <a
                    title="Dettaglio"
                    v-b-tooltip.hover
                    v-on:click="openExercise(record.data.id)"
                    v-if="
                      record.data.status == 'Completed' ||
                      record.data.status == 'Skipped'
                    "
                    class="la la-comment text-primary-75"
                    style="font-size: 35px; font-weight: 100; cursor: pointer"
                  >
                  </a>
                </div>
                <div class="col-1 mt-1 p-0">
                  <a
                    title="Ripeti"
                    v-b-tooltip.hover
                    v-if="record.data.status == 'Completed'"
                    v-on:click="openExercise(record.data.id, true)"
                    class="btn btn-icon btn btn-sm"
                    style="margin-top: 2px"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-danger">
                      <inline-svg
                        style="width: 33px !important; height: 33px !important"
                        src="media/svg/icons/Media/Repeat.svg"
                      ></inline-svg>
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <!--end::Text-->
          </div>
          <!--end::Item-->
        </div>
        <!--begin::Item-->
        <div
          v-if="portfolioOptions.length <= 2"
          class="d-flex align-items-center pb-4"
        >
          <!--begin::Text-->
          <div class="d-flex flex-column flex-grow-1">
            <a
              href="#"
              class="
                text-dark-75 text-hover-primary
                mb-1
                font-size-lg font-weight-bolder
              "
              >Slot libero</a
            >
            <span class="text-muted font-weight-bold"
              >Selezionare un esercizio dalla libreria generale</span
            >
          </div>
          <!--end::Text-->
        </div>
        <!--end::Item-->
        <!--begin::Item-->
        <div
          v-if="portfolioOptions.length <= 1"
          class="d-flex align-items-center pb-4"
        >
          <!--begin::Text-->
          <div class="d-flex flex-column flex-grow-1">
            <a
              href="#"
              class="
                text-dark-75 text-hover-primary
                mb-1
                font-size-lg font-weight-bolder
              "
              >Slot libero</a
            >
            <span class="text-muted font-weight-bold"
              >Selezionare un esercizio dalla libreria generale</span
            >
          </div>
          <!--end::Text-->
        </div>
        <!--end::Item-->
        <!--begin::Item-->
        <div
          v-if="portfolioOptions.length == 0"
          class="d-flex align-items-center pb-4"
        >
          <!--begin::Text-->
          <div class="d-flex flex-column flex-grow-1">
            <a
              href="#"
              class="
                text-dark-75 text-hover-primary
                mb-1
                font-size-lg font-weight-bolder
              "
              >Slot libero</a
            >
            <span class="text-muted font-weight-bold"
              >Selezionare un esercizio dalla libreria generale</span
            >
          </div>
          <!--end::Text-->
        </div>
        <!--end::Item-->
        <div
          class="d-flex"
          style="align-items: center; height: calc(20vh - 100px)"
        >
          <a
            v-on:click="openPortfolio()"
            class="btn btn-primary font-weight-bolder font-size-sm py-2 px-4"
            style="
              margin-left: auto;
              margin-right: auto;
              display: block;
              width: 150px;
            "
            >Vai al portfolio</a
          >
        </div>
      </div>
      <div class="pt-1" v-if="asset == 'Stocks'">
        <!--begin::Text-->
        <h3 class="card-title font-weight-bolder font-size-lg mt-3">
          Le esercitazioni della settimana
        </h3>
        <p
          v-if="
            subscriptions.find(
              (x) => x.data[0].type == 'stocks' && x.data[0].interval != 'year'
            )
          "
          class="text-dark-75 font-weight-nirmal font-size-lg m-0 pb-7"
        >
          Ogni settimana sarà possibile eseguire 3 esercitazioni.
        </p>
        <p
          v-if="
            subscriptions.find(
              (x) => x.data[0].type == 'stocks' && x.data[0].interval == 'year'
            )
          "
          class="text-dark-75 font-weight-nirmal font-size-lg m-0 pb-7"
        >
          In basso trovi le tue ultime 3 esercitazioni
        </p>
        <!--end::Text-->
        <div
          v-for="(record, i) in portfolioStocks.slice(
            portfolioStocks.length - 3 > 0 ? portfolioStocks.length - 3 : 0,
            portfolioStocks.length
          )"
          v-bind:key="i"
          style="font-size: 13px; text-align: left"
        >
          <!--begin::Item-->
          <div class="d-flex align-items-center pb-4">
            <!--begin::Text-->
            <div class="d-flex flex-column flex-grow-1">
              <div class="row">
                <div class="col-6">
                  <a
                    class="
                      text-dark-75 text-hover-primary
                      font-size-lg font-weight-bolder
                    "
                    >{{ record.extra.generalForm.saveName }}</a
                  >
                  <p class="text-muted font-weight-bold">
                    {{ record.extra.generalForm.sector }}
                  </p>
                  <!--span class="font-weight-bold">Data ultimo aggiornamento:</span-->
                </div>
                <div class="col-3 mt-2 p-0">
                  <span
                    v-if="
                      record.data.pl != undefined &&
                      record.data.status == 'Completed'
                    "
                    class="font-weight-bolder label label-lg label-inline mt-1"
                    v-bind:class="
                      record.data.pl > 0
                        ? 'label-success p-1 m-0'
                        : record.data.pl == 0
                        ? 'label-gray p-1 m-0'
                        : record.data.pl == undefined
                        ? ''
                        : 'label-danger'
                    "
                    style="padding: 12px 10px !important"
                    >{{
                      record.data.status == "Completed"
                        ? "$" + record.data.pl
                        : ""
                    }}</span
                  >
                </div>
                <div class="col-1 mt-2 p-0">
                  <a
                    title="Completato"
                    v-b-tooltip.hover
                    v-if="record.data.status == 'Completed'"
                    class="la la-check-circle text-success"
                    style="
                      font-size: 31px;
                      font-weight: 100;
                      cursor: default;
                      margin-left: -10px;
                    "
                  ></a>
                  <a
                    title="In corso"
                    v-b-tooltip.hover
                    v-if="record.data.status == 'In progress'"
                    class="la la-pause-circle-o text-warning"
                    style="
                      font-size: 35px;
                      font-weight: 100;
                      cursor: default;
                      margin-left: -12px;
                      margin-top: -8px;
                    "
                  ></a>
                  <a
                    title="Scartato"
                    v-b-tooltip.hover
                    v-if="record.data.status == 'Skipped'"
                    class="la la-times-circle text-danger"
                    style="
                      font-size: 30px;
                      font-weight: 100;
                      cursor: default;
                      margin-left: -10px;
                    "
                  ></a>
                </div>
                <div class="col-1 mt-1 p-0" style="margin-left: -10px">
                  <a
                    v-on:click="openExercise(record.data.id)"
                    title="Play"
                    v-if="record.data.status == 'In progress'"
                    class="btn btn-icon btn btn-sm btn-outline-primary"
                    style="
                      margin-top: 0px;
                      border: solid 2px;
                      font-size: 7px;
                      cursor: pointer;
                      margin-left: 5px;
                    "
                  >
                    <span class="svg-icon svg-icon-md">
                      <svg
                        version="1.1"
                        viewBox="0 0 24 24"
                        height="24px"
                        width="24px"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xmlns="http://www.w3.org/2000/svg"
                        alt="Play"
                        fill="#3F4254"
                      >
                        <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
                        <title xmlns="http://www.w3.org/2000/svg">Play</title>
                        <g
                          xmlns="http://www.w3.org/2000/svg"
                          id="Stockholm-icons-/-Media-/-Play"
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <rect
                            id="bound"
                            x="0"
                            y="0"
                            width="24"
                            height="24"
                          ></rect>
                          <path
                            d="M9.82866499,18.2771971 L16.5693679,12.3976203 C16.7774696,12.2161036 16.7990211,11.9002555 16.6175044,11.6921539 C16.6029128,11.6754252 16.5872233,11.6596867 16.5705402,11.6450431 L9.82983723,5.72838979 C9.62230202,5.54622572 9.30638833,5.56679309 9.12422426,5.7743283 C9.04415337,5.86555116 9,5.98278612 9,6.10416552 L9,17.9003957 C9,18.1765381 9.22385763,18.4003957 9.5,18.4003957 C9.62084305,18.4003957 9.73759731,18.3566309 9.82866499,18.2771971 Z"
                            id="Path-10"
                            fill="#3F4254"
                          ></path>
                        </g>
                      </svg>
                    </span>
                  </a>
                  <a
                    title="Dettaglio"
                    v-b-tooltip.hover
                    v-on:click="openExercise(record.data.id)"
                    v-if="
                      record.data.status == 'Completed' ||
                      record.data.status == 'Skipped'
                    "
                    class="la la-comment text-primary-75"
                    style="font-size: 35px; font-weight: 100; cursor: pointer"
                  >
                  </a>
                </div>
                <div class="col-1 mt-1 p-0">
                  <a
                    title="Ripeti"
                    v-b-tooltip.hover
                    v-if="record.data.status == 'Completed'"
                    v-on:click="openExercise(record.data.id, true)"
                    class="btn btn-icon btn btn-sm"
                    style="margin-top: 2px"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-danger">
                      <inline-svg
                        style="width: 33px !important; height: 33px !important"
                        src="media/svg/icons/Media/Repeat.svg"
                      ></inline-svg>
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <!--end::Text-->
          </div>
          <!--end::Item-->
        </div>
        <!--begin::Item-->
        <div
          v-if="portfolioStocks.length <= 2"
          class="d-flex align-items-center pb-4"
        >
          <!--begin::Text-->
          <div class="d-flex flex-column flex-grow-1">
            <a
              href="#"
              class="
                text-dark-75 text-hover-primary
                mb-1
                font-size-lg font-weight-bolder
              "
              >Slot libero</a
            >
            <span class="text-muted font-weight-bold"
              >Selezionare un esercizio dalla libreria generale</span
            >
          </div>
          <!--end::Text-->
        </div>
        <!--end::Item-->
        <!--begin::Item-->
        <div
          v-if="portfolioStocks.length <= 1"
          class="d-flex align-items-center pb-4"
        >
          <!--begin::Text-->
          <div class="d-flex flex-column flex-grow-1">
            <a
              href="#"
              class="
                text-dark-75 text-hover-primary
                mb-1
                font-size-lg font-weight-bolder
              "
              >Slot libero</a
            >
            <span class="text-muted font-weight-bold"
              >Selezionare un esercizio dalla libreria generale</span
            >
          </div>
          <!--end::Text-->
        </div>
        <!--end::Item-->
        <!--begin::Item-->
        <div
          v-if="portfolioStocks.length == 0"
          class="d-flex align-items-center pb-4"
        >
          <!--begin::Text-->
          <div class="d-flex flex-column flex-grow-1">
            <a
              href="#"
              class="
                text-dark-75 text-hover-primary
                mb-1
                font-size-lg font-weight-bolder
              "
              >Slot libero</a
            >
            <span class="text-muted font-weight-bold"
              >Selezionare un esercizio dalla libreria generale</span
            >
          </div>
          <!--end::Text-->
        </div>
        <!--end::Item-->
        <div
          class="d-flex"
          style="align-items: center; height: calc(20vh - 100px)"
        >
          <a
            v-on:click="openPortfolio()"
            class="btn btn-primary font-weight-bolder font-size-sm py-2 px-4"
            style="
              margin-left: auto;
              margin-right: auto;
              display: block;
              width: 150px;
            "
            >Vai al portfolio</a
          >
        </div>
      </div>
    </div>
    <!--end::Body-->
  </div>
  <!--end::Mixed Widget 14-->
</template>

<script>
//import Dropdown3 from "@/view/content/dropdown/Dropdown3.vue";
import { mapGetters } from "vuex";
import * as fb from "../../../../firebase";

// const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
//  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
// ];

export default {
  name: "widget-13",
  data() {
    return {
      asset: "",
      portfolio: [],
      portfolioCommodities: [],
      portfolioOptions: [],
      portfolioStocks: [],
      chartOptions: {},
      series: [],
      groupWeeks: ["Week 0"],
      plGroupByWeek: [0],
    };
  },
  components: {
    //Dropdown3,
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    email() {
      return this.$store.state.user.email;
    },
    name() {
      return this.$store.state.user.name;
    },
    subscriptions() {
      return this.$store.state.user.subscriptions;
    },
  },
  methods: {
    openExercise(i, repeat) {
      this.$root.$emit("open_ex", i, repeat); //like this
    },
    openPortfolio() {
      this.$parent.showPortfolio(this.portfolio);
    },
    updateEquity(asset) {
      this.plGroupByWeek = [0];
      this.groupWeeks = ["Week 0"];
      var results = this.portfolio.filter(
        (x) => x.data.status == "Completed" && x.data.asset == "Commodities"
      );
      if (asset == "Options")
        results = this.portfolio.filter(
          (x) => x.data.status == "Completed" && x.data.asset == "Options"
        );
      if (asset == "Stocks")
        results = this.portfolio.filter(
          (x) => x.data.status == "Completed" && x.data.asset == "Stocks"
        );
      var weeks = [];
      var counter = 1;
      for (var i = 0; i < results.length; i++) {
        // What's the first day of that week?
        var weekNumber = getSpecialMonday(
          results[i].data.upins_date.split(",")[0]
        );
        console.log(weekNumber);
        // Is it already in the array? YES
        if (
          i != 0 &&
          weeks.find((x) => x.number.getTime() == weekNumber.getTime()) !=
            undefined
        ) {
          var elem = weeks.find(
            (x) => x.number.getTime() == weekNumber.getTime()
          );
          this.plGroupByWeek[elem.counter] = parseFloat(
            parseFloat(this.plGroupByWeek[elem.counter]) +
              parseFloat(results[i].data.pl)
          ).toFixed(2);
          // Is it already in the array? NO
        } else {
          this.groupWeeks[counter] = "Week " + counter;
          this.plGroupByWeek[counter] = parseFloat(
            results[i].data.pl + parseFloat(this.plGroupByWeek[counter - 1])
          ).toFixed(2);
          weeks.push({ counter: counter, number: weekNumber });
          counter++;
        }
      }
      if (this.$refs.realtimeChart != undefined) {
        this.$refs.realtimeChart.updateSeries(
          [
            {
              //data: this.series[0].data,
              data: this.plGroupByWeek,
            },
          ],
          false,
          true
        );
        this.$refs.realtimeChart.updateOptions(
          [
            {
              //data: this.series[0].data,
              data: this.groupWeeks,
            },
          ],
          false,
          true
        );
      }
      /* already commented
      var date = new Date(doc.upins_date.seconds<10000000000 ?  doc.upins_date.seconds* 1000 : doc.upins_date.seconds);
      var pl = doc.pl;
      var month = monthNames[date.getMonth()];
      if(this.monthsChart.indexOf(month)>=0) {
        var currentPl = this.chartOptions.series[0].data[0];
        var updatePl = parseFloat(parseFloat(currentPl)+parseFloat(pl)).toFixed(2);
        this.chartOptions.series[0].data[0] = parseFloat(updatePl);
      } else {
        this.dataChart.push(parseFloat(pl));
        this.monthsChart.push(month);
      }
      console.log(date + pl);*/
    },
    updateLibrary(email) {
      this.portfolio = [];
      this.portfolioCommodities = [];
      this.portfolioOptions = [];
      this.portfolioStocks = [];
      this.groupWeeks = ["Week 0"];
      this.plGroupByWeek = [0];
      var that = this;
      //this.portfolioCommodities.find(x=>x.data.id == value).status='Completed';
      var allExercises = this.$store.state.siteData.exercises;
      var subQuery = fb.db
        .collection("saved-charts")
        .doc("chartslab@system.com")
        .collection("charts");
      subQuery
        .get()
        .then((querySnapshot) => {
          var promises = [];
          querySnapshot.forEach((doc) => {
            promises.push(doc);
          });
          return Promise.all(promises);
        })
        .then((results) => {
          console.log(results);
          //results = results.filter(x => x.asset == that.asset);
          allExercises = results;
          var mainQuery = fb.db
            .collection("portfolio")
            .doc(email)
            .collection("charts")
            .orderBy("upins_date", "asc");
          mainQuery
            .get()
            .then((querySnapshot) => {
              var promises = [];
              querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                var docExercise = allExercises.find((x) => x.id == doc.id);
                if (docExercise == undefined) {
                  console.log("ssss");
                }
                var docObj = new Object();
                docObj["data"] = doc.data();
                docObj["extra"] = docExercise.data();
                switch (docObj["data"].status) {
                  case "break":
                  case "ready_to_go":
                    docObj["data"].status = "In progress";
                    break;
                  case "skipped":
                    docObj["data"].status = "Skipped";
                    break;
                  case "completed":
                    //this.addEquity(docObj['data']);
                    promises.push(docObj["data"]);
                    docObj["data"].status = "Completed";
                    break;
                  default:
                    docObj["data"].status = "Not started";
                }
                docObj["data"].id = doc.id;
                var exerciseCreationDate = new Date(
                  docObj["data"].upins_date.seconds < 10000000000
                    ? docObj["data"].upins_date.seconds * 1000
                    : docObj["data"].upins_date.seconds
                );
                docObj["data"].upins_date =
                  exerciseCreationDate.toLocaleString();
                var asset = docObj.extra.generalForm.asset;
                // Show only strategies of current week
                // var thisWeek = getWeek(new Date());
                // var exerciseWeek = getWeek(new Date(docObj['extra'].generalForm.created.seconds));
                var firstDayOfWeek = getMonday(new Date()).toLocaleDateString();
                // exerciseCreationDate.setDate(exerciseCreationDate.getDate()+6);
                var firstDayOfWeekExercise =
                  getMonday(exerciseCreationDate).toLocaleDateString();
                //var diff = today <= exerciseCreationDate;
                var diff = firstDayOfWeekExercise == firstDayOfWeek;
                if (
                  asset == "Commodities" &&
                  /*thisWeek==exerciseWeek*/ (diff ||
                    docObj["data"].status == "In progress")
                )
                  this.portfolioCommodities.push(docObj);
                if (
                  asset == "Options" &&
                  /*thisWeek==exerciseWeek*/ (diff ||
                    docObj["data"].status == "In progress")
                )
                  this.portfolioOptions.push(docObj);
                if (
                  asset == "Stocks" &&
                  /*thisWeek==exerciseWeek*/ (diff ||
                    docObj["data"].status == "In progress")
                )
                  this.portfolioStocks.push(docObj);
                if (docObj["data"].status == "Completed")
                  this.portfolio.push(docObj);
                var status = new Object();
                status["commodities"] =
                  this.portfolioCommodities.length == 3 ? "red" : "green";
                status["options"] =
                  this.portfolioOptions.length == 3 ? "red" : "green";
                status["stocks"] =
                  this.portfolioStocks.length == 3 ? "red" : "green";
                this.$root.$emit("update_status", status); //like this
              });
              return Promise.all(promises);
            })
            .then((results) => {
              console.log(results);
              results = results.filter((x) => x.asset == that.asset);
              var weeks = [];
              var counter = 1;
              for (var i = 0; i < results.length; i++) {
                // What's the first day of that week?
                var weekNumber = getSpecialMonday(
                  results[i].upins_date.split(",")[0]
                );
                console.log(weekNumber);
                // Is it already in the array? YES
                if (
                  i != 0 &&
                  weeks.find(
                    (x) => x.number.getTime() == weekNumber.getTime()
                  ) != undefined
                ) {
                  var elem = weeks.find(
                    (x) => x.number.getTime() == weekNumber.getTime()
                  );
                  that.plGroupByWeek[elem.counter] = parseFloat(
                    parseFloat(that.plGroupByWeek[elem.counter]) +
                      parseFloat(results[i].pl)
                  ).toFixed(2);
                  // Is it already in the array? NO
                } else {
                  that.groupWeeks[counter] = "Week " + counter;
                  that.plGroupByWeek[counter] = parseFloat(
                    results[i].pl + parseFloat(that.plGroupByWeek[counter - 1])
                  ).toFixed(2);
                  weeks.push({ counter: counter, number: weekNumber });
                  counter++;
                }
              }
              // reference; kt_stats_widget_7_chart
              this.chartOptions = {
                series: [
                  {
                    name: "Net Profit",
                    data: this.plGroupByWeek,
                  },
                ],
                chart: {
                  fontFamily: "inherit",
                  type: "line",
                  height: 200,
                  toolbar: {
                    show: false,
                  },
                  zoom: {
                    enabled: false,
                  },
                  sparkline: {
                    enabled: false,
                  },
                },
                plotOptions: {},
                legend: {
                  show: false,
                },
                dataLabels: {
                  enabled: false,
                },
                fill: {
                  type: "solid",
                  opacity: 1,
                },
                stroke: {
                  curve: "smooth",
                  show: true,
                  width: 3,
                  colors: ["#3699FF"],
                },
                xaxis: {
                  categories: this.groupWeeks,
                  axisBorder: {
                    show: true,
                  },
                  axisTicks: {
                    show: true,
                  },
                  labels: {
                    show: true,
                    style: {
                      colors: "#3699FF",
                      fontSize: "12px",
                    },
                  },
                  crosshairs: {
                    show: true,
                    position: "front",
                    stroke: {
                      color: "#3699FF",
                      width: 1,
                      dashArray: 3,
                    },
                  },
                  tooltip: {
                    enabled: false,
                  },
                  lines: {
                    show: false,
                  },
                },
                yaxis: {
                  labels: {
                    show: true,
                    offsetX: -15,
                    style: {
                      colors: "#3699FF",
                      fontSize: "12px",
                    },
                  },
                  lines: {
                    show: true,
                  },
                },
                grid: {
                  show: true,
                },
                states: {
                  normal: {
                    filter: {
                      type: "none",
                      value: 0,
                    },
                  },
                  hover: {
                    filter: {
                      type: "none",
                      value: 0,
                    },
                  },
                  active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                      type: "none",
                      value: 0,
                    },
                  },
                },
                tooltip: {
                  style: {
                    fontSize: "12px",
                  },
                  y: {
                    formatter: function (val) {
                      return "$" + val;
                    },
                  },
                },
                colors: ["#3699FF"],
                markers: {
                  colors: ["#3699FF"],
                  strokeColor: ["#3699FF"],
                  strokeWidth: 3,
                },
              };
            });
        });
    },
  },
  mounted() {
    this.$root.$on("update_view", (value) => {
      // your code goes here
      this.asset = value;
    });
    this.$root.$on("update_library", (value) => {
      // Update portfolio record
      this.updateLibrary(value);
    });
    this.$root.$on("update_equity", (value) => {
      // Update portfolio record
      this.updateEquity(value);
    });
  },
  watch: {
    email: {
      immediate: true,
      handler(email) {
        if (email !== "") {
          this.updateLibrary(email);
        }
      },
    },
  },
};

/*function getWeek(dateString) {
  var dateParts = dateString.split("/");
  var currentdate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]); 
  var oneJan = new Date(currentdate.getFullYear(),0,1);
  var numberOfDays = Math.floor((currentdate - oneJan) / (24 * 60 * 60 * 1000));
  return Math.ceil(( currentdate.getDay() + 1 + numberOfDays) / 7);
}*/

function getSpecialMonday(d) {
  var dateParts = d.split("/");
  d = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
  var day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
}

function getMonday(d) {
  d = new Date(d);
  var day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
}
</script>
