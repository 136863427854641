<template>
  <div data-app>
    <!-- begin::Dashboard-->
    <div class="row" style="height: 90vh">
      <!-- PERFORMANCE + PERSONAL BOX -->
      <div
        v-bind:class="boxes.show ? '' : 'd-none'"
        class="col-md-12 col-lg-4 col-sm-12"
      >
        <PersonalBox></PersonalBox>
      </div>
      <!-- PERFORMANCE + PERSONAL BOX -->

      <!-- GENERAL LIBRARY -->
      <div
        v-bind:class="boxes.show ? '' : 'd-none'"
        class="col-md-12 col-lg-8 col-sm-12"
      >
        <GeneralLibrary></GeneralLibrary>
      </div>
      <!-- GENERAL LIBRARY -->
      <b-modal size="lg" id="intro" hide-footer>
        <template #modal-title>
          <span style="text-align: left; font-size: 20px">{{
            exercise.saveName
          }}</span>
        </template>
        <div class="row justify-content-center">
          <div class="col-lg-12 col-md-6 col-xl-12 col-sm-3">
            <div
              v-html="
                exercise.playback != undefined
                  ? exercise.playback.messages.intro
                  : ''
              "
            />
            <a
              class="
                btn btn-sm btn-primary
                font-weight-bolder
                text-uppercase
                mt-5
                mb-5
                p-5
                w-100
                font-size-lg
              "
              @click="$bvModal.hide('intro')"
              >Selezioniamo l'ingresso</a
            >
          </div>
        </div>
      </b-modal>
      <b-modal
        id="status_break"
        hide-footer
        :modal-class="myclass"
        @hide="resetModal"
      >
        <template #modal-title>
          <span style="text-align: left; font-size: 20px">{{
            status_break_title
          }}</span>
        </template>
        <div class="row justify-content-center">
          <div class="col-lg-12 col-md-6 col-xl-12 col-sm-3">
            <p v-if="p_l > 0" style="text-align: center; font-size: 16px">
              La strategia ha un profitto di {{ p_l }} $
            </p>
            <p v-if="p_l < 0" style="text-align: center; font-size: 16px">
              La strategia ha una perdita di {{ p_l }} $
            </p>
            <h3
              v-if="p_l < 0"
              class="btn btn-outline-danger d-block ml-auto mr-auto mt-3 mb-5"
              style="
                font-size: 20px;
                color: #f64e60;
                background-color: transparent;
                border-color: #f64e60;
                width: 200px;
              "
            >
              <div class="row mt-0 mb-0">
                <div class="col-4 p-1">
                  <p
                    v-if="this.$store.state[this.namespace].asset != 'Options'"
                    class="text-dark-75 font-weight-bolder m-1"
                  >
                    P/L:
                  </p>
                </div>
                <div class="col-8 pt-2 p-1">{{ p_l }} $</div>
              </div>
            </h3>
            <h3
              v-if="p_l > 0"
              class="btn btn-success-dark d-block ml-auto mr-auto mt-3 mb-5"
              style="
                font-size: 20px;
                color: #1bc5bd;
                background-color: transparent;
                border-color: #1bc5bd;
                width: 200px;
              "
            >
              <div class="row mt-0 mb-0">
                <div class="col-4 p-1">
                  <p
                    v-if="this.$store.state[this.namespace].asset != 'Options'"
                    class="text-dark-75 font-weight-bolder m-1"
                  >
                    P/L:
                  </p>
                </div>
                <div class="col-8 p-1">{{ p_l }} $</div>
              </div>
            </h3>

            <a
              class="
                btn btn-sm btn-primary
                font-weight-bolder
                text-uppercase
                mt-2
                w-100
                bigplay
              "
              @click="
                choice = 'go';
                $bvModal.hide('status_break');
                play();
              "
              >{{ countDown != 0 ? countDown : "PLAY" }}</a
            >
          </div>
        </div>
      </b-modal>
      <b-modal
        id="status_stop"
        hide-footer
        :modal-class="myclass"
        @hide="resetModal"
      >
        <template #modal-title>
          <span style="text-align: left; font-size: 20px">Fine esercizio</span>
        </template>
        <div class="row justify-content-center">
          <div class="col-lg-12 col-md-6 col-xl-12 col-sm-3">
            <p style="text-align: center; font-size: 16px">
              La strategia ha avuto perdita di {{ exercise.margin }} $
            </p>
            <h3
              class="btn btn-outline-danger d-block ml-auto mr-auto mt-3 mb-5"
              style="
                font-size: 20px;
                color: #f64e60;
                background-color: transparent;
                border-color: #f64e60;
                width: 200px;
              "
            >
              <div class="row mt-0 mb-0">
                <div class="col-4 p-1">
                  <p
                    v-if="this.$store.state[this.namespace].asset != 'Options'"
                    class="text-dark-75 font-weight-bolder m-1"
                  >
                    P/L:
                  </p>
                </div>
                <div class="col-8 pt-2 p-1">{{ exercise.margin }} $</div>
              </div>
            </h3>
            <div class="col-12">
              <button
                variant="success"
                class="btn mt-3 btn-md btn-primary m-auto d-flex"
                @click="
                  $root.$emit('get_loss');
                  choice = 'results';
                  $bvModal.hide('status_stop');
                "
              >
                Vai ai risultati
              </button>
            </div>
          </div>
        </div>
      </b-modal>

      <!-- WIZARD -->

      <!-- begin:: WIZARD - LEFT SIDE -->
      <div class="col-md-3 col-lg-3 col-sm-12 col-xl-2" v-if="wizard.show">
        <!--begin::Stats Widget 3-->
        <div
          v-if="levels.show"
          class="card card-custom bgi-no-repeat card-stretch gutter-b"
          style="
            background-position: right top;
            background-size: 30% auto;
            background-image: url(media/svg/shapes/abstract-1.svg);
          "
        >
          <!--begin::body-->
          <div class="card-body">
            <!--a href="#" class="card-title font-weight-bold text-muted text-hover-primary font-size-h5">{{ exercise.saveName }}</a-->
            <div class="font-weight-bold text-primary mt-3 mb-5">
              CARATTERISTICHE
            </div>
            <div class="container p-0 mb-3">
              <div class="row">
                <div class="col-7 pt-0 pb-0 pr-0">
                  <p
                    class="
                      text-dark-75
                      font-weight-bolder
                      pt-1
                      pb-1
                      font-size-1rem
                      m-0
                    "
                  >
                    SETTORE
                  </p>
                </div>
                <div class="col-5 p-0">
                  <p class="font-weight-lighter pt-1 pb-1 font-size-1rem m-0">
                    {{ exercise.sector }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-7 pt-0 pb-0 pr-0">
                  <p
                    class="
                      text-dark-75
                      font-weight-bolder
                      pt-1
                      pb-1
                      font-size-1rem
                      m-0
                    "
                  >
                    STRATEGIA
                  </p>
                </div>
                <div class="col-5 p-0">
                  <p class="font-weight-lighter pt-1 pb-1 font-size-1rem m-0">
                    {{ exercise.strategy }}
                  </p>
                </div>
              </div>
              <div class="row" v-if="exercise.type != ''">
                <div class="col-7 pt-0 pb-0 pr-0">
                  <p
                    class="
                      text-dark-75
                      font-weight-bolder
                      pt-1
                      pb-1
                      font-size-1rem
                      m-0
                    "
                  >
                    TIPO
                  </p>
                </div>
                <div class="col-5 p-0">
                  <p class="font-weight-lighter pt-1 pb-1 font-size-1rem m-0">
                    {{ exercise.type }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-7 pt-0 pb-0 pr-0">
                  <p
                    class="
                      text-dark-75
                      font-weight-bolder
                      pt-1
                      pb-1
                      font-size-1rem
                      m-0
                    "
                  >
                    POSIZIONE
                  </p>
                </div>
                <div class="col-5 p-0">
                  <p class="font-weight-lighter pt-1 pb-1 font-size-1rem m-0">
                    {{ exercise.buySell == "sell" ? "Sell" : "Buy" }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-7 pt-0 pb-0 pr-0">
                  <p
                    class="
                      text-dark-75
                      font-weight-bolder
                      pt-1
                      pb-1
                      font-size-1rem
                      m-0
                    "
                  >
                    PERIODO
                  </p>
                </div>
                <div class="col-5 p-0">
                  <p class="font-weight-lighter pt-1 pb-1 font-size-1rem m-0">
                    {{ exercise.tradingPeriod }}
                  </p>
                </div>
              </div>
              <div class="row" v-if="exercise.asset == 'Commodities'">
                <div class="col-7 pt-0 pb-0 pr-0">
                  <p
                    class="
                      text-dark-75
                      font-weight-bolder
                      pt-1
                      pb-1
                      font-size-1rem
                      m-0
                    "
                  >
                    STAGIONALIT&Agrave;
                  </p>
                </div>
                <div class="col-5 p-0">
                  <p class="font-weight-lighter pt-1 pb-1 font-size-1rem m-0">
                    {{ exercise.seasonal }}
                  </p>
                </div>
              </div>
              <div class="row" v-if="exercise.asset == 'Options'">
                <div class="col-7 pt-0 pb-0 pr-0">
                  <p
                    class="
                      text-dark-75
                      font-weight-bolder
                      pt-1
                      pb-1
                      font-size-1rem
                      m-0
                    "
                  >
                    PREMIO
                  </p>
                </div>
                <div class="col-5 p-0">
                  <p class="font-weight-lighter pt-1 pb-1 font-size-1rem m-0">
                    $ {{ exercise.prize }}
                  </p>
                </div>
              </div>
              <div class="row" v-if="exercise.asset != 'Options'">
                <div class="col-7 pt-0 pb-0 pr-0">
                  <p
                    class="
                      text-dark-75
                      font-weight-bolder
                      pt-1
                      pb-1
                      font-size-1rem
                      m-0
                    "
                  >
                    {{ exercise.asset != "Stocks" ? "RSI " : "MACD " }}
                  </p>
                </div>
                <div class="col-5 p-0">
                  <p class="font-weight-lighter pt-1 pb-1 font-size-1rem m-0">
                    {{ exercise.rsi }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-7 pt-0 pb-0 pr-0">
                  <p
                    class="
                      text-dark-75
                      font-weight-bolder
                      pt-1
                      pb-1
                      font-size-1rem
                      m-0
                    "
                  >
                    RISCHIO
                  </p>
                </div>
                <div class="col-5 p-0">
                  <p class="font-weight-lighter pt-1 pb-1 font-size-1rem m-0">
                    {{ exercise.risk }}
                  </p>
                </div>
              </div>
            </div>
            <div class="font-weight-bold text-primary mt-5 mb-5">INGRESSO</div>
            <div class="mt-5 mb-5">
              <div class="row row-paddingless mb-2">
                <div class="col-12 p-0" v-if="exercise.asset != 'Options'">
                  <div class="d-flex align-items-center mb-2">
                    <div
                      @click="showAlert('bronze')"
                      class="
                        btn
                        d-inline-flex
                        align-items-center
                        btn-md
                        bronze
                        w-100
                      "
                      style="color: black; background-color: rgb(235 201 161)"
                    >
                      <div class="flex-column text-left pr-3">
                        <span class="text-dark-75 font-weight-bold font-size-sm"
                          >BRONZE</span
                        >
                        <span class="font-weight-bolder font-size-sm pl-2"
                          >$
                          {{ exercise.chartParameters.levels[0].price }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 p-0" v-if="exercise.asset != 'Options'">
                  <div class="d-flex align-items-center mb-2">
                    <div
                      @click="showAlert('silver')"
                      class="
                        btn
                        d-inline-flex
                        align-items-center
                        btn-md
                        silver
                        w-100
                      "
                      style="color: black; background-color: rgb(225 224 224)"
                    >
                      <div class="flex-column text-right pr-3">
                        <span class="text-dark-75 font-weight-bold font-size-sm"
                          >SILVER</span
                        >
                        <span class="font-weight-bolder font-size-sm pl-2"
                          >$
                          {{ exercise.chartParameters.levels[1].price }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 p-0" v-if="exercise.asset != 'Options'">
                  <div class="d-flex align-items-center mb-2">
                    <div
                      @click="showAlert('gold')"
                      class="
                        btn
                        d-inline-flex
                        align-items-center
                        btn-md
                        gold
                        w-100
                      "
                      style="
                        background-color: rgb(245 227 177);
                        min-width: 100px;
                      "
                    >
                      <div class="flex-column text-left pr-3">
                        <span class="text-dark-75 font-weight-bold font-size-sm"
                          >GOLD</span
                        >
                        <span
                          class="font-weight-bolder font-size-sm pl-2"
                          style="color: black"
                          >$
                          {{ exercise.chartParameters.levels[2].price }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 p-0" v-if="exercise.asset == 'Options'">
                  <div class="d-flex align-items-center mt-5 mb-5">
                    <div
                      @click="showAlert('yes')"
                      class="
                        btn btn-success
                        d-inline-flex
                        align-items-center
                        btn-md
                        entro
                        w-100
                      "
                    >
                      <div class="flex-column text-left pr-3">
                        <span class="text-dark-75 font-weight-bold font-size-sm"
                          >ENTRO</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 p-0">
                  <div class="d-flex align-items-center mb-5">
                    <div
                      @click="showAlert('no')"
                      class="
                        btn
                        d-inline-flex
                        align-items-center
                        btn-md
                        non_entro
                        w-100
                      "
                      style="background-color: #fbe3e5; min-width: 100px"
                    >
                      <div class="flex-column text-left pr-3">
                        <span class="text-dark-75 font-weight-bold font-size-sm"
                          >NON ENTRO</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="position: absolute; bottom: 25px; width: 80%">
                <v-btn
                  class="w-100 back mt-3"
                  style="
                    float: left;
                    background: rgb(54, 153, 255);
                    color: white;
                    margin-top: 25px;
                    font-size: 11.5px;
                    padding: 0px 5px;
                  "
                  @click="refresh"
                >
                  Torna alla libreria
                </v-btn>
              </div>
            </div>
          </div>
          <!--end::Body-->
        </div>
        <!--end::Stats Widget 3-->
        <!--begin::Stats Widget 3-->
        <div
          v-if="simulator.show"
          class="card card-custom bgi-no-repeat card-stretch gutter-b"
          style="
            background-position: right top;
            background-size: 30% auto;
            background-image: url(media/svg/shapes/abstract-1.svg);
          "
        >
          <!--begin::body-->
          <div class="card-body">
            <!--a href="#" class="card-title font-weight-bold text-muted text-hover-primary font-size-h5">{{ exercise.saveName }}</a-->
            <div class="font-weight-bold text-primary mt-3 mb-5">
              SIMULAZIONE
            </div>
            <p class="text-dark-75 font-weight-bolder font-size-h5 mt-4">
              INGRESSO
            </p>
            <p
              v-if="exercise.asset == 'Options' && exercise.level == 'yes'"
              class="btn d-inline-flex align-items-center btn-md bronze w-100"
              style="
                color: black;
                background-color: rgb(235 201 161) !important;
                cursor: default;
              "
            >
              <span class="text-dark-75 font-weight-bold font-size-sm"
                >A MERCATO</span
              >
            </p>
            <p
              v-if="exercise.level == 'no'"
              class="btn d-inline-flex align-items-center btn-md bronze w-100"
              style="
                color: black;
                background-color: rgb(235 201 161);
                cursor: default;
              "
            >
              <span class="text-dark-75 font-weight-bold font-size-sm"
                >NON EFFETTUATO</span
              >
            </p>
            <p
              v-if="exercise.asset != 'Options' && exercise.level == 'Bronze'"
              class="btn d-inline-flex align-items-center btn-md bronze w-100"
              style="
                color: black;
                background-color: rgb(235 201 161) !important;
                cursor: default;
              "
            >
              <span class="text-dark-75 font-weight-bold font-size-sm"
                >BRONZE</span
              >
              <span class="font-weight-bolder font-size-sm pl-2"
                >$ {{ exercise.chartParameters.levels[0].price }}</span
              >
            </p>
            <p
              v-if="exercise.asset != 'Options' && exercise.level == 'Silver'"
              class="btn d-inline-flex align-items-center btn-md silver w-100"
              style="
                color: black;
                background-color: rgb(225 224 224) !important;
                cursor: default;
              "
            >
              <span class="text-dark-75 font-weight-bold font-size-sm"
                >SILVER</span
              >
              <span class="font-weight-bolder font-size-sm pl-2"
                >$ {{ exercise.chartParameters.levels[0].price }}</span
              >
            </p>
            <p
              v-if="exercise.asset != 'Options' && exercise.level == 'Gold'"
              class="btn d-inline-flex align-items-center btn-md gold w-100"
              style="
                color: black;
                background-color: rgb(245 227 177) !important;
                cursor: default;
              "
            >
              <span class="text-dark-75 font-weight-bold font-size-sm"
                >GOLD</span
              >
              <span class="font-weight-bolder font-size-sm pl-2"
                >$ {{ exercise.chartParameters.levels[0].price }}</span
              >
            </p>
            <p
              v-if="exercise.chartParameters.levels[0].stop_price"
              class="btn d-inline-flex align-items-center btn-md w-100"
              style="
                color: black;
                background-color: rgb(245 177 177) !important;
                cursor: default;
              "
            >
              <span class="text-dark-75 font-weight-bold font-size-sm"
                >STOP LOSS</span
              >
              <span class="font-weight-bolder font-size-sm pl-2"
                >$ {{ exercise.margin }}</span
              >
            </p>
            <p
              v-if="exercise.asset == 'Stocks'"
              class="btn d-inline-flex align-items-center btn-md w-100"
              style="
                color: black;
                background-color: rgb(133 213 172) !important;
                cursor: default;
              "
            >
              <span class="text-dark-75 font-weight-bold font-size-sm"
                >N° CONTRATTI</span
              >
              <span class="font-weight-bolder font-size-sm pl-2">
                {{ exercise.stock_multiplier }}</span
              >
            </p>
            <p
              v-if="exercise.asset == 'Options'"
              class="btn d-inline-flex align-items-center btn-md gold w-100"
              style="
                color: black;
                background-color: rgb(127 213 133) !important;
                cursor: default;
              "
            >
              <span class="text-dark-75 font-weight-bold font-size-sm"
                >PREMIO</span
              >
              <span class="font-weight-bolder font-size-sm pl-2"
                >$ {{ exercise.prize }}</span
              >
            </p>
            <h3
              v-if="exercise.asset != 'Options' && p_l == 0"
              class="mb-2 btn w-100"
              style="
                font-size: 20px;
                color: #181c32;
                background-color: transparent;
                border-color: #181c32;
              "
            >
              <div class="row mt-0 mb-0">
                <div class="col-4 p-0">
                  <p
                    v-if="exercise.asset != 'Options'"
                    class="text-dark-75 font-weight-bolder m-1"
                  >
                    P/L:
                  </p>
                </div>
                <div class="col-8 p-1">0 $</div>
              </div>
            </h3>
            <h3
              v-if="exercise.asset != 'Options' && p_l < 0"
              class="mb-2 btn btn-outline-danger w-100"
              style="
                font-size: 20px;
                color: #f64e60;
                background-color: transparent;
                border-color: #f64e60;
              "
            >
              <div class="row mt-0 mb-0">
                <div class="col-4 p-0">
                  <p
                    v-if="exercise.asset != 'Options'"
                    class="text-dark-75 font-weight-bolder m-1"
                  >
                    P/L:
                  </p>
                </div>
                <div class="col-8 p-1">{{ p_l }} $</div>
              </div>
            </h3>
            <h3
              v-if="exercise.asset != 'Options' && p_l > 0"
              class="mb-2 btn btn-outline-success w-100"
              style="
                font-size: 20px;
                color: #1bc5bd;
                background-color: transparent;
                border-color: #1bc5bd;
              "
            >
              <div class="row mt-0 mb-0">
                <div class="col-4 p-0">
                  <p
                    v-if="exercise.asset != 'Options'"
                    class="text-dark-75 font-weight-bolder m-1"
                  >
                    P/L:
                  </p>
                </div>
                <div class="col-8 p-1">{{ p_l }} $</div>
              </div>
            </h3>
            <div class="row">
              <div class="col-6 pr-3 pr-0 mb-2 mt-2">
                <a
                  class="
                    btn btn-sm btn-primary
                    font-weight-bolder
                    text-uppercase
                    mt-2
                    w-100
                  "
                  @click="play"
                  :class="{ disabled: this.play_button }"
                  >PLAY</a
                >
              </div>
              <div class="col-6 pr-3 pr-0 mb-2 mt-2">
                <a
                  class="
                    btn btn-sm btn-primary
                    font-weight-bolder
                    text-uppercase
                    mt-2
                    w-100
                  "
                  :class="{ disabled: this.pause_button }"
                  @click="pause"
                  >PAUSE</a
                >
              </div>
            </div>
            <div class="font-weight-bold text-primary mt-5 mb-3">
              CARATTERISTICHE
            </div>
            <div class="container p-0 mb-3">
              <div class="row">
                <div class="col-7 pt-0 pb-0 pr-0">
                  <p
                    class="
                      text-dark-75
                      font-weight-bolder
                      pt-1
                      pb-1
                      font-size-1rem
                      m-0
                    "
                  >
                    SETTORE
                  </p>
                </div>
                <div class="col-5 p-0">
                  <p class="font-weight-lighter pt-1 pb-1 font-size-1rem m-0">
                    {{ exercise.sector }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-7 pt-0 pb-0 pr-0">
                  <p
                    class="
                      text-dark-75
                      font-weight-bolder
                      pt-1
                      pb-1
                      font-size-1rem
                      m-0
                    "
                  >
                    STRATEGIA
                  </p>
                </div>
                <div class="col-5 p-0">
                  <p class="font-weight-lighter pt-1 pb-1 font-size-1rem m-0">
                    {{ exercise.strategy }}
                  </p>
                </div>
              </div>
              <div class="row" v-if="exercise.type != ''">
                <div class="col-7 pt-0 pb-0 pr-0">
                  <p
                    class="
                      text-dark-75
                      font-weight-bolder
                      pt-1
                      pb-1
                      font-size-1rem
                      m-0
                    "
                  >
                    TIPO
                  </p>
                </div>
                <div class="col-5 p-0">
                  <p class="font-weight-lighter pt-1 pb-1 font-size-1rem m-0">
                    {{ exercise.type }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-7 pt-0 pb-0 pr-0">
                  <p
                    class="
                      text-dark-75
                      font-weight-bolder
                      pt-1
                      pb-1
                      font-size-1rem
                      m-0
                    "
                  >
                    POSIZIONE
                  </p>
                </div>
                <div class="col-5 p-0">
                  <p class="font-weight-lighter pt-1 pb-1 font-size-1rem m-0">
                    {{ exercise.buySell == "sell" ? "Sell" : "Buy" }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-7 pt-0 pb-0 pr-0">
                  <p
                    class="
                      text-dark-75
                      font-weight-bolder
                      pt-1
                      pb-1
                      font-size-1rem
                      m-0
                    "
                  >
                    PERIODO
                  </p>
                </div>
                <div class="col-5 p-0">
                  <p class="font-weight-lighter pt-1 pb-1 font-size-1rem m-0">
                    {{ exercise.tradingPeriod }}
                  </p>
                </div>
              </div>
              <div class="row" v-if="exercise.asset == 'Commodities'">
                <div class="col-7 pt-0 pb-0 pr-0">
                  <p
                    class="
                      text-dark-75
                      font-weight-bolder
                      pt-1
                      pb-1
                      font-size-1rem
                      m-0
                    "
                  >
                    STAGIONALIT&Agrave;
                  </p>
                </div>
                <div class="col-5 p-0">
                  <p class="font-weight-lighter pt-1 pb-1 font-size-1rem m-0">
                    {{ exercise.seasonal }}
                  </p>
                </div>
              </div>
              <div class="row" v-if="exercise.asset != 'Options'">
                <div class="col-7 pt-0 pb-0 pr-0">
                  <p
                    class="
                      text-dark-75
                      font-weight-bolder
                      pt-1
                      pb-1
                      font-size-1rem
                      m-0
                    "
                  >
                    {{ exercise.asset != "Stocks" ? "RSI" : "MACD" }}
                  </p>
                </div>
                <div class="col-5 p-0">
                  <p class="font-weight-lighter pt-1 pb-1 font-size-1rem m-0">
                    {{ exercise.rsi }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-7 pt-0 pb-0 pr-0">
                  <p
                    class="
                      text-dark-75
                      font-weight-bolder
                      pt-1
                      pb-1
                      font-size-1rem
                      m-0
                    "
                  >
                    RISCHIO
                  </p>
                </div>
                <div class="col-5 p-0">
                  <p class="font-weight-lighter pt-1 pb-1 font-size-1rem m-0">
                    {{ exercise.risk }}
                  </p>
                </div>
              </div>
            </div>
            <div style="position: absolute; bottom: 25px; width: 80%">
              <v-btn
                class="w-100 back mt-3"
                style="
                  float: left;
                  background: #3699ff;
                  color: white;
                  margin-top: 25px;
                "
                @click="refresh"
              >
                Torna alla libreria
              </v-btn>
            </div>
          </div>
        </div>
      </div>

      <!-- begin:: WIZARD - RIGHT SIDE -->
      <div class="col-md-9 col-lg-9 col-sm-12 col-xl-10" v-if="wizard.show">
        <div class="card card-custom card-stretch gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">
                {{ exercise.saveName }}
              </span>
            </h3>
            <div class="card-toolbar"></div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pb-0" style="padding-top: 15px !important">
            <basic-chart-output
              v-if="
                exercise.asset == 'Commodities' ||
                exercise.underlying == 'Future'
              "
              v-bind:windowHeight="'700'"
              v-bind:ref="'playback'"
              v-bind:namespace="namespace"
              style="border: 1px solid darkgray"
            ></basic-chart-output>
            <stock-chart-output
              v-if="
                exercise.asset == 'Stocks' || exercise.underlying == 'Azionario'
              "
              v-bind:windowHeight="'700'"
              v-bind:ref="'playback'"
              v-bind:namespace="namespaceStocks"
              style="border: 1px solid darkgray"
            >
            </stock-chart-output>
          </div>
          <!--end::Body-->
        </div>
      </div>
      <!-- end:: WIZARD - RIGHT SIDE -->

      <!-- RESULTS-->
      <div class="col-md-3 col-lg-3 col-sm-12 col-xl-2" v-if="result.show">
        <!--begin::Stats Widget 3-->
        <div
          class="card card-custom bgi-no-repeat card-stretch gutter-b"
          style="
            background-position: right top;
            background-size: 30% auto;
            background-image: url(media/svg/shapes/abstract-1.svg);
          "
        >
          <!--begin::body-->
          <div class="card-body">
            <!--a href="#" class="card-title font-weight-bold text-muted text-hover-primary font-size-h5">{{ exercise.saveName }}</a-->
            <div class="font-weight-bold text-primary mt-3 mb-5">RISULTATI</div>
            <p class="text-dark-75 font-weight-bolder font-size-h5 mt-4">
              INGRESSO
            </p>
            <p
              v-if="exercise.asset == 'Options' && exercise.level == 'yes'"
              class="btn d-inline-flex align-items-center btn-md bronze w-100"
              style="
                color: black;
                background-color: rgb(235 201 161) !important;
                cursor: default;
              "
            >
              <span class="text-dark-75 font-weight-bold font-size-sm"
                >A MERCATO</span
              >
            </p>
            <p
              v-if="exercise.level == 'no'"
              class="btn d-inline-flex align-items-center btn-md w-100"
              style="color: black; background-color: #fbe3e5; cursor: default"
            >
              <span class="text-dark-75 font-weight-bold font-size-sm"
                >NON EFFETTUATO</span
              >
            </p>
            <p
              v-if="exercise.asset != 'Options' && exercise.level == 'bronze'"
              class="btn d-inline-flex align-items-center btn-md bronze w-100"
              style="
                color: black;
                background-color: rgb(235 201 161) !important;
                cursor: default;
              "
            >
              <span class="text-dark-75 font-weight-bold font-size-sm"
                >BRONZE</span
              >
              <span class="font-weight-bolder font-size-sm pl-2"
                >$ {{ exercise.chartParameters.levels[0].price }}</span
              >
            </p>
            <p
              v-if="exercise.asset != 'Options' && exercise.level == 'silver'"
              class="btn d-inline-flex align-items-center btn-md silver w-100"
              style="
                color: black;
                background-color: rgb(225 224 224) !important;
                cursor: default;
              "
            >
              <span class="text-dark-75 font-weight-bold font-size-sm"
                >SILVER</span
              >
              <span class="font-weight-bolder font-size-sm pl-2"
                >$ {{ exercise.chartParameters.levels[0].price }}</span
              >
            </p>
            <p
              v-if="exercise.asset != 'Options' && exercise.level == 'gold'"
              class="btn d-inline-flex align-items-center btn-md gold w-100"
              style="
                color: black;
                background-color: rgb(245 227 177) !important;
                cursor: default;
              "
            >
              <span class="text-dark-75 font-weight-bold font-size-sm"
                >GOLD</span
              >
              <span class="font-weight-bolder font-size-sm pl-2"
                >$ {{ exercise.chartParameters.levels[0].price }}</span
              >
            </p>
            <p
              v-if="exercise.chartParameters.levels[0].stop_price"
              class="btn d-inline-flex align-items-center btn-md w-100"
              style="
                color: black;
                background-color: rgb(245 177 177) !important;
                cursor: default;
              "
            >
              <span class="text-dark-75 font-weight-bold font-size-sm"
                >STOP LOSS</span
              >
              <span class="font-weight-bolder font-size-sm pl-2"
                >$ {{ exercise.margin }}</span
              >
            </p>
            <p
              v-if="exercise.asset == 'Stocks'"
              class="btn d-inline-flex align-items-center btn-md w-100"
              style="
                color: black;
                background-color: rgb(133 213 172) !important;
                cursor: default;
              "
            >
              <span class="text-dark-75 font-weight-bold font-size-sm"
                >N° contratti</span
              >
              <span class="font-weight-bolder font-size-sm pl-2">
                {{ exercise.stock_multiplier }}</span
              >
            </p>
            <p
              v-if="exercise.asset == 'Options'"
              class="btn d-inline-flex align-items-center btn-md gold w-100"
              style="
                color: black;
                background-color: rgb(127 213 133) !important;
                cursor: default;
              "
            >
              <span class="text-dark-75 font-weight-bold font-size-sm"
                >PREMIO</span
              >
              <span class="font-weight-bolder font-size-sm pl-2"
                >$ {{ exercise.prize }}</span
              >
            </p>
            <div class="font-weight-bold text-primary mt-5 mb-3">
              CARATTERISTICHE
            </div>
            <div class="container p-0 mb-3">
              <div class="row">
                <div class="col-7 pt-0 pb-0 pr-0">
                  <p
                    class="
                      text-dark-75
                      font-weight-bolder
                      pt-1
                      pb-1
                      font-size-1rem
                      m-0
                    "
                  >
                    SETTORE
                  </p>
                </div>
                <div class="col-5 p-0">
                  <p class="font-weight-lighter pt-1 pb-1 font-size-1rem m-0">
                    {{ exercise.sector }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-7 pt-0 pb-0 pr-0">
                  <p
                    class="
                      text-dark-75
                      font-weight-bolder
                      pt-1
                      pb-1
                      font-size-1rem
                      m-0
                    "
                  >
                    STRATEGIA
                  </p>
                </div>
                <div class="col-5 p-0">
                  <p class="font-weight-lighter pt-1 pb-1 font-size-1rem m-0">
                    {{ exercise.strategy }}
                  </p>
                </div>
              </div>
              <div class="row" v-if="exercise.type != ''">
                <div class="col-7 pt-0 pb-0 pr-0">
                  <p
                    class="
                      text-dark-75
                      font-weight-bolder
                      pt-1
                      pb-1
                      font-size-1rem
                      m-0
                    "
                  >
                    TIPO
                  </p>
                </div>
                <div class="col-5 p-0">
                  <p class="font-weight-lighter pt-1 pb-1 font-size-1rem m-0">
                    {{ exercise.type }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-7 pt-0 pb-0 pr-0">
                  <p
                    class="
                      text-dark-75
                      font-weight-bolder
                      pt-1
                      pb-1
                      font-size-1rem
                      m-0
                    "
                  >
                    POSIZIONE
                  </p>
                </div>
                <div class="col-5 p-0">
                  <p class="font-weight-lighter pt-1 pb-1 font-size-1rem m-0">
                    {{ exercise.buySell == "sell" ? "Sell" : "Buy" }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-7 pt-0 pb-0 pr-0">
                  <p
                    class="
                      text-dark-75
                      font-weight-bolder
                      pt-1
                      pb-1
                      font-size-1rem
                      m-0
                    "
                  >
                    PERIODO
                  </p>
                </div>
                <div class="col-5 p-0">
                  <p class="font-weight-lighter pt-1 pb-1 font-size-1rem m-0">
                    {{ exercise.tradingPeriod }}
                  </p>
                </div>
              </div>
              <div class="row" v-if="exercise.asset == 'Commodities'">
                <div class="col-7 pt-0 pb-0 pr-0">
                  <p
                    class="
                      text-dark-75
                      font-weight-bolder
                      pt-1
                      pb-1
                      font-size-1rem
                      m-0
                    "
                  >
                    STAGIONALIT&Agrave;
                  </p>
                </div>
                <div class="col-5 p-0">
                  <p class="font-weight-lighter pt-1 pb-1 font-size-1rem m-0">
                    {{ exercise.seasonal }}
                  </p>
                </div>
              </div>
              <div class="row" v-if="exercise.asset != 'Options'">
                <div class="col-7 pt-0 pb-0 pr-0">
                  <p
                    class="
                      text-dark-75
                      font-weight-bolder
                      pt-1
                      pb-1
                      font-size-1rem
                      m-0
                    "
                  >
                    {{ exercise.asset != "Stocks" ? "RSI" : "MACD" }}
                  </p>
                </div>
                <div class="col-5 p-0" v-if="exercise.asset != 'Options'">
                  <p class="font-weight-lighter pt-1 pb-1 font-size-1rem m-0">
                    {{ exercise.rsi }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-7 pt-0 pb-0 pr-0">
                  <p
                    class="
                      text-dark-75
                      font-weight-bolder
                      pt-1
                      pb-1
                      font-size-1rem
                      m-0
                    "
                  >
                    RISCHIO
                  </p>
                </div>
                <div class="col-5 p-0">
                  <p class="font-weight-lighter pt-1 pb-1 font-size-1rem m-0">
                    {{ exercise.risk }}
                  </p>
                </div>
              </div>
            </div>
            <div style="position: absolute; bottom: 25px; width: 80%">
              <v-btn
                class="w-100 back mt-3"
                style="
                  float: left;
                  background: #3699ff;
                  color: white;
                  margin-top: 25px;
                "
                @click="refresh"
              >
                Torna alla libreria
              </v-btn>
            </div>
          </div>
          <!--end::Body-->
        </div>
        <!--end::Stats Widget 3-->
      </div>
      <div class="col-md-9 col-lg-9 col-sm-12 col-xl-10" v-if="result.show">
        <div class="card card-custom card-stretch gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">{{
                exercise.saveName
              }}</span>
            </h3>
            <div class="card-toolbar"></div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-3 pb-0">
            <div>
              <p class="ml-5 font-size-lg text-dark" v-if="exercise.pl < 0">
                Con questo trade hai perso
              </p>
              <p class="ml-5 font-size-lg text-dark" v-if="exercise.pl > 0">
                Con questo trade hai guadagnato
              </p>
              <p
                class="ml-5 font-size-lg text-dark"
                v-if="exercise.pl == 0 || exercise.pl == undefined"
              >
                Con questo trade non hai ottenuto né un profitto né una perdita.
              </p>
              <p
                class="ml-5 font-size-lg text-dark"
                v-if="exercise.level == undefined"
              >
                Hai scelto di non entrare in questa operazione.
              </p>
            </div>
            <div class="row">
              <div class="col-5">
                <h3
                  v-if="exercise.pl < 0"
                  class="display-1 mb-10 btn btn-outline-danger"
                  style="
                    padding: 30px 15px;
                    margin: 15px;
                    color: #f64e60;
                    background-color: transparent;
                    border-color: #f64e60;
                    width: 80%;
                  "
                >
                  {{ exercise.pl }} $
                </h3>
                <h3
                  v-if="exercise.pl > 0"
                  class="display-1 mb-10 btn btn-outline-success"
                  style="
                    padding: 30px 15px;
                    margin: 15px;
                    color: #1bc5bd;
                    background-color: transparent;
                    border-color: #1bc5bd;
                    width: 80%;
                  "
                >
                  {{ exercise.pl }} $
                </h3>
                <h3
                  v-if="exercise.pl == 0"
                  class="display-1 mb-10 btn btn-outline-dark"
                  style="
                    padding: 30px 15px;
                    margin: 15px;
                    color: #3f4254;
                    background-color: transparent;
                    border-color: #3f4254;
                    width: 80%;
                  "
                >
                  0 $
                </h3>
                <h3
                  v-if="exercise.pl == undefined && exercise.level == undefined"
                  class="display-1 mb-10 btn btn-outline-dark"
                  style="
                    padding: 30px 15px;
                    margin: 15px;
                    color: #3f4254;
                    background-color: transparent;
                    border-color: #3f4254;
                    width: 80%;
                  "
                >
                  0 $
                </h3>
              </div>
              <div class="col-7 pt-5">
                <span
                  style="align-items: center; height: 200px; font-size: 17px"
                  v-html="exercise.playback.messages.result"
                ></span>
              </div>
            </div>
          </div>
          <div class="card-body pt-3 pb-0">
            <!--begin::Heading-->
            <p class="pl-2 font-size-lg font-weight-bolder text-dark">
              All'interno del tuo portafoglio troverai l'andamento delle tue
              performance!
            </p>
            <!--end::Heading-->
          </div>
          <!--end::Body-->
        </div>
      </div>
      <!-- RESULTS-->

      <!-- PORTFOLIO -->
      <div class="col-12" v-if="portfolio.show">
        <div class="card card-custom">
          <!--begin::Header-->
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark"
                >Portfolio</span
              >
              <span class="mt-3 font-weight-bold font-size-md"
                >Current equity: {{ portfolio.equity }} $</span
              >
            </h3>
            <div class="card-toolbar">
              <a
                @click="close"
                class="btn btn-primary font-weight-bolder font-size-sm back"
                >Torna alla Libreria</a
              >
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body py-0">
            <!--begin::Table-->
            <div class="table-responsive">
              <table
                class="table table-head-custom table-vertical-center"
                id="kt_advance_table_widget_4"
              >
                <thead>
                  <tr class="text-left">
                    <th class="pl-0" style="min-width: 120px">NAME</th>
                    <th style="min-width: 110px">ASSET</th>
                    <th style="min-width: 110px">
                      <span class="text-primary">DATE</span>
                    </th>
                    <th style="min-width: 120px">P/L</th>
                    <!--th class="pr-0 text-right" style="min-width: 160px">Action</th-->
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(record, i) in portfolio.records"
                    v-bind:key="i"
                    style="font-size: 13px; text-align: left"
                  >
                    <td class="pl-0">
                      <a
                        href="#"
                        class="
                          text-dark-75
                          font-weight-bolder
                          text-hover-primary
                          font-size-lg
                        "
                        >{{ record.extra.generalForm.saveName }}</a
                      >
                    </td>
                    <td>
                      <span
                        class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          font-size-lg
                        "
                        >{{ record.data.asset }}</span
                      >
                    </td>
                    <td>
                      <span
                        class="
                          text-primary
                          font-weight-bolder
                          d-block
                          font-size-lg
                        "
                        >{{ record.data.upins_date }}</span
                      >
                    </td>
                    <td>
                      <span
                        class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          font-size-lg
                        "
                        >{{ record.data.pl }}</span
                      >
                    </td>
                    <!--td class="pr-0 text-right">
                      <a href="#" class="btn btn-icon btn btn-hover-primary btn-sm">
                        <span class="svg-icon svg-icon-md svg-icon-primary">
                          <begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/General/Settings-1.svg>
                          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                              <rect x="0" y="0" width="24" height="24"></rect>
                              <path d="M7,3 L17,3 C19.209139,3 21,4.790861 21,7 C21,9.209139 19.209139,11 17,11 L7,11 C4.790861,11 3,9.209139 3,7 C3,4.790861 4.790861,3 7,3 Z M7,9 C8.1045695,9 9,8.1045695 9,7 C9,5.8954305 8.1045695,5 7,5 C5.8954305,5 5,5.8954305 5,7 C5,8.1045695 5.8954305,9 7,9 Z" fill="#000000"></path>
                              <path d="M7,13 L17,13 C19.209139,13 21,14.790861 21,17 C21,19.209139 19.209139,21 17,21 L7,21 C4.790861,21 3,19.209139 3,17 C3,14.790861 4.790861,13 7,13 Z M17,19 C18.1045695,19 19,18.1045695 19,17 C19,15.8954305 18.1045695,15 17,15 C15.8954305,15 15,15.8954305 15,17 C15,18.1045695 15.8954305,19 17,19 Z" fill="#000000" opacity="0.3"></path>
                            </g>
                          </svg>
                        </span>
                      </a>
                      <a href="#" class="btn btn-icon btn btn-hover-primary btn-sm mx-3">
                        <span class="svg-icon svg-icon-md svg-icon-primary">
                          <begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Communication/Write.svg>
                          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                              <rect x="0" y="0" width="24" height="24"></rect>
                              <path d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953)"></path>
                              <path d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                            </g>
                          </svg>
                        </span>
                      </a>
                      <a href="#" class="btn btn-icon btn btn-hover-primary btn-sm">
                        <span class="svg-icon svg-icon-md svg-icon-primary">
                          <begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/General/Trash.svg>
                          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                              <rect x="0" y="0" width="24" height="24"></rect>
                              <path d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z" fill="#000000" fill-rule="nonzero"></path>
                              <path d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"></path>
                            </g>
                          </svg>
                        </span>
                      </a>
                    </td-->
                  </tr>
                </tbody>
              </table>
            </div>
            <!--end::Table-->
          </div>
          <!--end::Body-->
        </div>
      </div>
      <!--PORTFOLIO-->
    </div>
    <!--end ::row-->

    <!--end ::Dashboard -->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import GeneralLibrary from "@/view/content/widgets/advance-table/Widget2.vue";
import PersonalBox from "@/view/content/widgets/stats/Widget13.vue";
import basicChartOutput from "@/components/programs/BasicChart/basic-chart-output";
import stockChartOutput from "@/components/programs/StockChart/stock-chart-output";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import * as fb from "../../firebase";
import {
  defaultInitialState,
  defaultInitialStockState,
  monthNames,
} from "../../js/chartslab";

export default {
  name: "dashboard",
  data() {
    return {
      multiplier: 1,
      play_button: false,
      pause_button: true,
      p_l: "-",
      replay_date: "",
      show: true,
      boxes: {
        show: true,
      },
      wizard: {
        show: false,
      },
      levels: {
        show: false,
      },
      simulator: {
        show: false,
      },
      result: {
        show: false,
      },
      portfolio: {
        show: false,
        records: [],
        equity: 0,
      },
      exercise: "",
      docId: "",
      asset: "",
      namespace: "generalFormTab1",
      namespaceStocks: "generalFormTab2",
      myclass: ["myclass"],
      choice: null,
      status_break_title: "",
      countDown: 3,
    };
  },
  components: {
    basicChartOutput,
    stockChartOutput,
    GeneralLibrary,
    PersonalBox,
  },
  created() {
    //this.initialize();
    console.log("Playback.vue created() starting.");
    console.log("initialState=", initialState);
    this.$store.commit("generalFormTab1/setGeneralForm", initialState);
    this.$store.commit("generalFormTab1/setModuleName", "generalFormTab1");
    //this.$store.dispatch("generalFormTab1/getContractListsFromServer");
    this.$store.commit("generalFormTab2/setGeneralForm", initialStockState);
    this.$store.commit("generalFormTab2/setModuleName", "generalFormTab2");
    // this.$store.dispatch("generalFormTab2/getContractListsFromServer");
    this.$store.state.siteData.exercises = [];
    /*fb.db.collection('saved-charts')
          .doc('chartslab@system.com').collection('charts').onSnapshot((querySnapshot) => {
          querySnapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              console.log(doc.id, " => ", doc.data());
              if(this.$store.state.siteData.exercises.find(x=>x.id==doc.id)<0)
              this.$store.state.siteData.exercises.push(doc);
          });
    });*/
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Exercise" : "Edit Exercise";
    },
    email() {
      // console.log("this.$store.state.user.email=", this.$store.state.user.email);
      return this.$store.state.user.email;
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    this.$root.$on("p_l_update", (value) => {
      // your code goes here
      this.updatePl(value);
    });
    this.$root.$on("replay_date_update", (date) => {
      // your code goes here
      this.updateReplayDate(date);
    });
    this.$root.$on("get_loss", () => {
      // your code goes here
      this.saveLoss();
    });
    this.$root.$on("get_profit", () => {
      // your code goes here
      this.saveProfit();
    });
    this.$root.$on("skip_breakpoint", () => {
      // your code goes here
      this.skipBreakpoint();
    });
    this.$root.$on("refresh_page", () => {
      // your code goes here
      this.showOnlyBoxes();
    });
    this.$root.$on("set_pause", () => {
      // your code goes here
      this.pause_button = true;
      this.play_button = false;
    });
  },
  methods: {
    addStopLine(level) {
      var gap = level.to - level.price;
      return {
        from: parseFloat(level.stop_price) - gap,
        to: parseFloat(level.stop_price) + gap,
        color: "red",
        name: "stop-price",
        price: parseFloat(level.stop_price),
      };
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    resetModal() {
      if (this.choice == null)
        this.$nextTick(() => {
          this.$bvModal.show("status_break");
        });
    },
    createTradingPeriod(start, end) {
      var monthStart = monthNames[new Date(start).getMonth()];
      var monthEnd = monthNames[new Date(end).getMonth()];
      return monthStart + " - " + monthEnd;
    },
    skipBreakpoint() {
      var pl;
      if (this.p_l != 0) {
        pl = Number(
          this.p_l
            .toString()
            .replace("$", "")
            .replace(/[^0-9.-]+/g, "")
        );
      } else {
        pl = this.p_l;
      }
      if (!this.exercise.repeat)
        this.$root.$emit(
          "update_personal_library",
          this.docId,
          pl,
          this.replay_date
        ); //like this
    },
    saveProfit() {
      var pl;
      var obj = new Object();
      obj["generalForm"] = this.exercise;
      obj["level"] = this.exercise.level;
      if (
        this.exercise.asset == "Commodities" ||
        this.exercise.asset == "Stocks"
      ) {
        obj["level"] = this.exercise.level.toLowerCase();
        if (this.p_l != 0) {
          pl = Number(
            this.p_l
              .toString()
              .replace("$", "")
              .replace(/[^0-9.-]+/g, "")
          );
        } else {
          pl = this.p_l;
        }
        obj["pl"] = pl;
        // Update state in general library
        if (!this.exercise.repeat)
          this.$root.$emit(
            "update_personal_library",
            this.docId,
            parseFloat(pl)
          ); //like this
        // Update state in personal library
        this.$root.$emit("update_library", this.$store.state.user.email); //like this
        this.showResult(obj);
      } else {
        pl = Number(this.exercise.prize);
        obj["pl"] = pl;
        // Update state in general library
        if (!this.exercise.repeat)
          this.$root.$emit(
            "update_personal_library",
            this.docId,
            parseFloat(pl)
          ); //like this
        // Update state in personal library
        this.$root.$emit("update_library", this.$store.state.user.email); //like this
        this.showResult(obj);
      }
      this.exercise.pl = pl;
    },
    saveLoss() {
      var pl;
      var obj = new Object();
      obj["generalForm"] = this.exercise;
      obj["level"] = this.exercise.level;
      if (
        this.exercise.asset == "Commodities" ||
        this.exercise.asset == "Stocks"
      ) {
        obj["level"] = this.exercise.level.toLowerCase();
        pl = Number(
          this.p_l
            .toString()
            .replace("$", "")
            .replace(/[^0-9.-]+/g, "")
        );
        obj["pl"] = pl;
        // Update state in general library
        if (!this.exercise.repeat)
          this.$root.$emit(
            "update_personal_library",
            this.docId,
            parseFloat(pl)
          ); //like this
        // Update state in personal library
        this.$root.$emit("update_library", this.$store.state.user.email); //like this
        this.showResult(obj);
      } else {
        pl = -Number(this.exercise.margin);
        obj["pl"] = pl;
        // Update state in general library
        if (!this.exercise.repeat)
          this.$root.$emit(
            "update_personal_library",
            this.docId,
            parseFloat(pl)
          ); //like this
        // Update state in personal library
        this.$root.$emit("update_library", this.$store.state.user.email); //like this
        this.showResult(obj);
      }
      this.exercise.pl = pl;
    },
    updatePl(pl) {
      if (this.exercise.asset != "Stocks") {
        this.p_l = Number(
          pl
            .toString()
            .replace("$", "")
            .replace(/[^0-9.-]+/g, "")
        );
      } else {
        this.p_l = parseFloat(
          Number(
            pl
              .toString()
              .replace("$", "")
              .replace(/[^0-9.-]+/g, "")
          ) * Number(this.exercise.stock_multiplier)
        ).toFixed(2);
      }
      if (
        this.p_l < 0 &&
        Math.abs(this.p_l) > parseFloat(this.exercise.margin)
      ) {
        this.p_l = -parseFloat(this.exercise.margin);
        this.pause();
        this.$bvModal.show("status_stop");
      }
    },
    updateReplayDate(date) {
      this.replay_date = date;
    },
    play() {
      this.play_button = true;
      this.pause_button = false;
      this.$root.$emit("playback_play", this.asset); //like this
    },
    pause() {
      this.pause_button = true;
      this.play_button = false;
      this.$root.$emit("playback_pause"); //like this
    },
    updateState() {
      console.log("updateState() starting.");
      //var newUnitMove = getCommoditiesObject()['CL'].unitMove;
      //console.log(newUnitMove);
      if (this.editedItem.ticker != "")
        initialState.selected[0] = this.editedItem.ticker;
      initialState.buySell = this.editedItem.buySell;
      this.$store.commit(this.namespace + "/setGeneralForm", initialState);
      this.$store.dispatch(this.namespace + "/getContractListsFromServer");
      console.log("updateState() starting.");
    },
    showAlert(threshold) {
      var item = this.exercise;
      var docId = this.docId;
      var that = this;
      var obj = new Object();
      obj["generalForm"] = item;
      obj["pl"] = 0;
      var title =
        "<b>Hai selezionato la soglia " + threshold.toUpperCase() + "</b>";
      if (threshold == "yes") title = "Hai deciso di ENTRARE A MERCATO";
      if (threshold == "no") title = "Hai deciso di NON ENTRARE A MERCATO";
      Swal.fire({
        title: title,
        text: "Proseguendo non sarà possibile modificare la scelta!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "rgb(138 211 187)",
        cancelButtonColor: "rgb(255 133 133)",
        cancelButtonText: "Annulla",
        confirmButtonText: "Si, prosegui!",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          var levels = new Array();
          var breakpoints = new Array();
          levels = item.chartParameters.levels.filter(
            (x) => x.name.indexOf(threshold) >= 0
          );
          if (levels.length > 0 && levels[0].stop_price != undefined)
            levels.push(this.addStopLine(levels[0]));
          breakpoints = item.playback.breakpoints.filter(
            (x) => x.level.indexOf(threshold) >= 0
          );
          if (threshold != "yes" && threshold != "no") {
            item.chartParameters.levels = levels;
            item.playback.breakpoints = breakpoints;
            this.$store.commit("generalFormTab1/setGeneralForm", item);
            this.$store.dispatch("generalFormTab1/getContractListsFromServer");
            item.level =
              threshold == "silver"
                ? "Silver"
                : threshold == "bronze"
                ? "Bronze"
                : "Gold";
          } else {
            item.level = threshold;
          }
          if (
            threshold == "yes" ||
            threshold == "bronze" ||
            threshold == "silver" ||
            threshold == "gold"
          ) {
            this.status_break_title = "Iniziamo...";
            this.$bvModal.show("status_break");
            this.countDownTimer();
          }
          that.simulator.show = true;
          that.levels.show = false;
          that.result.show = false;
          that.exercise.level = item.level;
          // Save in portfolio
          var object = new Object();
          object["status"] = threshold != "no" ? "ready_to_go" : "skipped";
          object["upins_date"] = new Date();
          object["asset"] = item.asset;
          object["level"] = threshold;
          if (!item.repeat) {
            fb.db
              .collection("portfolio")
              .doc(this.$store.state.user.email)
              .collection("charts")
              .doc(docId)
              .set(object)
              .then(() => {
                console.log("updated!");
                if (object["status"] == "skipped") {
                  obj.level = "no";
                  this.showResult(obj);
                }
              });
          }
        }
      });
    },
    showPortfolio(portfolio) {
      this.boxes.show = false;
      this.wizard.show = false;
      this.portfolio.show = true;
      this.portfolio.records = portfolio;
      var equity = 0;
      for (var i in portfolio) {
        equity += portfolio[i].data.pl;
      }
      this.portfolio.equity = parseFloat(equity).toFixed(2);
    },
    showSimulator(item) {
      this.boxes.show = false;
      this.wizard.show = true;
      this.levels.show = false;
      this.simulator.show = true;
      this.result.show = false;
      console.log(item.settore);
      this.docId = item.id;
      this.asset = item.generalForm.asset;
      this.exercise = item.generalForm;
      var namespace = "generalFormTab1";
      var levels = new Array();
      var breakpoints = new Array();
      this.status_break_title = "Riprendi da dove hai lasciato";
      this.$bvModal.show("status_break");
      this.countDownTimer();
      this.exercise.level =
        item.level == "silver"
          ? "Silver"
          : item.level == "bronze"
          ? "Bronze"
          : item.level == "gold"
          ? "Gold"
          : item.level;
      this.exercise.tradingPeriod = this.createTradingPeriod(
        item.generalForm.playback.start,
        item.generalForm.playback.end
      );
      if (
        this.exercise.asset == "Commodities" ||
        this.exercise.asset == "Stocks"
      ) {
        levels = item.generalForm.chartParameters.levels.filter(
          (x) => x.name.indexOf(item.level) >= 0
        );
        if (levels.length > 0 && levels[0].stop_price != undefined)
          levels.push(this.addStopLine(levels[0]));
        if (item.playback != undefined) {
          breakpoints = item.playback.breakpoints.filter(
            (x) => x.level.indexOf(item.level) >= 0
          );
          if (levels.length > 0)
            item.generalForm.chartParameters.levels = levels;
          item.playback.breakpoints = breakpoints;
        } else {
          breakpoints = item.generalForm.playback.breakpoints.filter(
            (x) => x.level.indexOf(item.level) >= 0
          );
          if (levels.length > 0)
            item.generalForm.chartParameters.levels = levels;
          item.generalForm.playback.breakpoints = breakpoints;
        }
        if (
          item.status == "In progress" &&
          item.break != undefined &&
          item.break
        ) {
          //var breakpoints = item.generalForm.playback.breakpoints;
          var upDate = breakpoints.find((x) => x.level == item.level).date;
          item.generalForm.playback.breakpoints = [];
          item.generalForm.playback.start = upDate;
          if (item.pl > 0) item.generalForm.chartParameters.entryType = "stop";
          else item.generalForm.chartParameters.entryType = "limit";
          item.generalForm.open = upDate;
        }
        this.p_l = item.pl == undefined ? "0" : item.pl.toString();
      }
      item.generalForm.showTradingPeriod = false;
      item.generalForm.showPlaybackControl = true;
      if (item.generalForm.asset == "Stocks") {
        namespace = "generalFormTab2";
      } else if (item.generalForm.asset == "Options") {
        if (item.generalForm.underlying == "Azionario") {
          namespace = "generalFormTab2";
          this.exercise.addMACDPanel = false;
          this.exercise.addRSIPanel = true;
        }
      }
      this.$store.commit(namespace + "/setGeneralForm", item.generalForm);
      this.$store.commit(namespace + "/setModuleName", namespace);
      this.$store.dispatch(namespace + "/getContractListsFromServer");
    },
    showResult(item) {
      this.boxes.show = false;
      this.wizard.show = false;
      this.levels.show = false;
      this.simulator.show = false;
      this.result.show = true;
      console.log(item.settore);
      this.exercise = item.generalForm;
      this.exercise.pl = item.pl == undefined ? 0 : item.pl;
      this.exercise.level = item.level;
      this.exercise.tradingPeriod = this.createTradingPeriod(
        item.generalForm.playback.start,
        item.generalForm.playback.end
      );
    },
    showOnlyWizard(item) {
      this.boxes.show = false;
      this.wizard.show = true;
      this.levels.show = true;
      this.simulator.show = false;
      this.result.show = false;
      console.log(item.settore);
      this.docId = item.id;
      this.exercise = item.generalForm;
      this.exercise.repeat = item.repeat;
      this.exercise.tradingPeriod = this.createTradingPeriod(
        item.generalForm.playback.start,
        item.generalForm.playback.end
      );
      var namespace = "generalFormTab1";
      if (item.generalForm.asset == "Stocks") {
        namespace = "generalFormTab2";
      } else if (item.generalForm.asset == "Options") {
        if (item.generalForm.underlying == "Azionario") {
          namespace = "generalFormTab2";
          this.exercise.addMACDPanel = false;
          this.exercise.addRSIPanel = true;
        }
      }
      this.p_l = 0;
      item.generalForm.showTradingPeriod = false;
      item.generalForm.showPlaybackControl = true;
      this.$store.commit(namespace + "/setGeneralForm", this.exercise);
      this.$store.commit(namespace + "/setModuleName", namespace);
      this.$store.dispatch(namespace + "/getContractListsFromServer");
      this.$bvModal.show("intro");
      //this.$parent.strategy.sector=this.day[i].settore;
      //this.$children[0].settore = settore;
      //this.$refs.wizard.initSettore(settore);
    },
    refresh() {
      this.close()
      //location.reload();
    },
    showOnlyBoxes() {
      this.boxes.show = true;
      this.wizard.show = false;
      this.simulator.show = true;
      this.result.show = false;
    },
    close() {
      //location.reload();
      this.pause();
      this.boxes.show = true;
      this.wizard.show = false;
      this.portfolio.show = false;
      this.result.show = false;
      // Update state in general library
      this.$root.$emit("update_assets", this.$store.state.user.email); //like this
      // Update state in personal library
      this.$root.$emit("update_library", this.$store.state.user.email); //like this
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
let initialState = defaultInitialState();
let initialStockState = defaultInitialStockState();
</script>
<style>
#generalFormTab1-playbackControl,
#generalFormTab1-playbackControl + div,
#generalFormTab2-playbackControl,
#generalFormTab2-playbackControl + div,
.amcharts-amexport-menu {
  display: none !important;
}
#generalFormTab1-chartDiv,
#generalFormTab2-chartDiv {
  height: 550px;
}
.card-header {
  min-height: 35px !important;
}
.label-inline {
  min-width: 60px !important;
}
.label-lg {
  width: 85px !important;
}
.card.card-custom > .card-body {
  padding: 2rem 1.5rem !important;
}
.bronze:hover {
  background-color: rgb(213 149 73) !important;
}
.silver:hover {
  background-color: rgb(177 177 177) !important;
}
.gold:hover {
  background-color: rgb(209 175 82) !important;
}
.entro:hover {
  background-color: #0bb7af !important;
}
.non_entro:hover {
  background-color: #ee2d41 !important;
}
.back {
  background: rgb(255 255 255) !important;
  color: rgb(54, 153, 255) !important;
  border: solid 1px rgb(54, 153, 255) !important;
}
.swal2-cancel,
.swal2-confirm {
  width: 150px !important;
}
.swal2-cancel:hover {
  background-color: #ee2d41 !important;
}
.swal2-confirm:hover {
  background-color: #4f975e !important;
}
.table-responsive {
  height: calc(70vh);
}
.font-size-1rem {
  font-size: 1rem !important;
}
.close {
  display: none;
}
.modal-title {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.bigplay {
  height: 70px;
  font-size: 40px;
}
#status_break > div,
#status_stop > div {
  position: fixed;
  top: 100px;
  left: calc(50% - 250px);
  bottom: 40px;
  z-index: 100;
}
#status_break > .modal-dialog > .modal-content,
#status_stop > .modal-dialog > .modal-content {
  display: inline-block;
}
#intro > div {
  position: fixed;
  top: 40px;
  left: calc(25%);
  bottom: 40px;
  z-index: 100;
}
#intro > .modal-dialog > .modal-content {
  display: inline-block;
}
#intro ul {
  margin-left: 30px;
}
#intro span {
  font-size: 15px;
}
#intro___BV_modal_body_ {
  overflow-x: scroll;
  height: 500px;
}
a.text-success:hover,
a.text-success:focus {
  color: #1bc5bd !important;
}
a.text-warning:hover,
a.text-warning:focus {
  color: #ffa800 !important;
}
</style>
